/**
  Types
 */

export const Types = {
  GET_REQUEST_PURSHASE: 'purshase/GET_REQUEST_PURSHASE',
  GET_REQUEST_BACKGROUND_PURSHASE: 'purshase/GET_REQUEST_BACKGROUND_PURSHASE',
  GET_SUCCESS_PURSHASE: 'purshase/GET_SUCCESS_PURSHASE',
  REQUEST_DELETE_PURSHASE: 'purshase/REQUEST_DELETE_PURSHASE',
  SUCCESS_DELETE_PURSHASE: 'purshase/SUCCESS_DELETE_PURSHASE',
};

/**
  Reducers
 */
const INITIAL_STATE = {
  loading: false,
  purshases: [],
};

export default function use(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST_PURSHASE:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_REQUEST_BACKGROUND_PURSHASE:
      return {
        ...state,
        loading: false,
      };
    case Types.GET_SUCCESS_PURSHASE:
      return {
        ...state,
        loading: false,
        purshases: action.payload.data,
      };
    case Types.REQUEST_DELETE_PURSHASE:
      return {
        ...state,
        loading: false,
      };
    case Types.SUCCESS_DELETE_PURSHASE:
      return {
        ...state,
        loading: false,
        purshases: action.payload.data,
      };
    default:
      return state;
  }
}

/**
 Actions
 */

export const Creators = {
  getRequestPurshase: () => ({
    type: Types.GET_REQUEST_PURSHASE,
  }),
  getRequestBackgroundPurshase: () => ({
    type: Types.GET_REQUEST_BACKGROUND_PURSHASE,
  }),
  getSuccessPurshase: (data) => ({
    type: Types.GET_SUCCESS_PURSHASE,
    payload: { data },
  }),
};
