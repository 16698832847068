/**
  Types
 */

export const Types = {
  GET_REQUEST_CLIENT: 'client/GET_REQUEST_CLIENT',
  GET_REQUEST_BACKGROUND_CLIENT: 'client/GET_REQUEST_BACKGROUND_CLIENT',
  GET_SUCCESS_CLIENT: 'client/GET_SUCCESS_CLIENT',
  REQUEST_DELETE_CLIENT: 'client/REQUEST_DELETE_CLIENT',
  SUCCESS_DELETE_CLIENT: 'client/SUCCESS_DELETE_CLIENT',
};

/**
  Reducers
 */
const INITIAL_STATE = {
  loading: false,
  clients: [],
};

export default function use(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST_CLIENT:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_REQUEST_BACKGROUND_CLIENT:
      return {
        ...state,
        loading: false,
      };
    case Types.GET_SUCCESS_CLIENT:
      return {
        ...state,
        loading: false,
        clients: action.payload.data,
      };
    case Types.REQUEST_DELETE_CLIENT:
      return {
        ...state,
        loading: false,
      };
    case Types.SUCCESS_DELETE_CLIENT:
      return {
        ...state,
        loading: false,
        clients: action.payload.data,
      };
    default:
      return state;
  }
}

/**
 Actions
 */

export const Creators = {
  getRequestClient: () => ({
    type: Types.GET_REQUEST_CLIENT,
  }),
  getRequestBackgroundClient: () => ({
    type: Types.GET_REQUEST_BACKGROUND_CLIENT,
  }),
  getSuccessClient: (data) => ({
    type: Types.GET_SUCCESS_CLIENT,
    payload: { data },
  }),
  requestDelete: (data) => ({
    type: Types.REQUEST_DELETE_CLIENT,
    payload: { data },
  }),
  successDelete: (data) => ({
    type: Types.SUCCESS_DELETE_CLIENT,
    payload: { data },
  }),
};
