import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';


// Components
import { Layout } from 'antd';

import GlobalUser from '~/components/GlobalUser';

import Sidebar from '~/components/Sidebar';
import Header from '~/components/Header';
import Menu from '~/components/Menu';

import Login from '~/pages/Login';
import Main from '~/pages/Main';

import Estoque from '~/pages/Estoque';
import EstoqueNovoProduto from '~/pages/Estoque/NovoProduto';
import EstoqueNovoProdutoLote from '~/pages/Estoque/NovoProdutoLote';

import Departamentos from '~/pages/Departamentos';

import Clientes from '~/pages/Clientes';
import ClientesLote from '~/pages/Clientes/ClientsLote';

import VendasAndamento from '~/pages/Vendas/andamento';
import VendasConcluida from '~/pages/Vendas/concluida';
import VendasExterna from '~/pages/Vendas/externa';
import VendasAvulsa from '~/pages/Vendas/avulsa';

import Cashback from '~/pages/Cashback';
import CashbackExtrato from '~/pages/Cashback/Extrato/extrato';

import Ceps from '~/pages/Ceps';
import Settings from '~/pages/Settings';

import Mapa from '~/pages/Mapa';

// import { Row, Col } from '~/styles/components';

import { isAuthenticated, logout } from './services/auth';
import Mensalidades from './pages/Mensalidades';
import KexiFarna from './pages/KexiFarna';


const {
  Content, Footer,
} = Layout;

const Logout = () => {
  logout();
  window.location.href = '/';
  // return <Redirect to="/" />;
};


export const PrivateRouter = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated() ? (
      <>
        <GlobalUser>
          <Layout style={{ background: '#f3f3f3' }}>
            {/* <Sidebar /> */}
            <Layout>
              <Header className="site-layout-sub-header-background" />
              <Menu />
              <Content style={{ margin: 0, background: '#f3f3f3' }}>
                <div
                  className="site-layout-background"
                  style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                    marginTop: 10,
                    minHeight: ('calc(100vh - 40px)'),
                  }}
                >
                  <Component {...props} />
                </div>
              </Content>
              {/* <Footer style={{ textAlign: 'center' }}>©2020 Qesh Payments</Footer> */}
            </Layout>
          </Layout>
        </GlobalUser>
      </>
    ) : (
        // eslint-disable-next-line
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    ))}
  />
);


export default function MainRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route path="/logout" component={Logout} />

      <Route exact path="/estabelecimentos" component={Mapa} />
      <Route exact path="/mapa" component={Mapa} />


      <PrivateRouter exact path="/dashboard" component={Main} />
      <PrivateRouter exact path="/estoque" component={Estoque} />
      <PrivateRouter exact path="/estoque/novo-produto" component={EstoqueNovoProduto} />
      <PrivateRouter exact path="/estoque/novo-produto-lote" component={EstoqueNovoProdutoLote} />
      <PrivateRouter exact path="/departamentos" component={Departamentos} />

      <PrivateRouter exact path="/clientes" component={Clientes} />
      <PrivateRouter exact path="/clientes/novo-cliente-lote" component={ClientesLote} />

      <PrivateRouter exact path="/vendas/em-andamento" component={VendasAndamento} />
      <PrivateRouter exact path="/vendas/concluida" component={VendasConcluida} />
      <PrivateRouter exact path="/vendas/externa" component={VendasExterna} />
      <PrivateRouter exact path="/vendas/avulsa" component={VendasAvulsa} />

      <PrivateRouter exact path="/ceps" component={Ceps} />

      <PrivateRouter exact path="/cashback" component={Cashback} />
      <PrivateRouter exact path="/cashback/extrato" component={CashbackExtrato} />

      <PrivateRouter exact path="/settings" component={Settings} />
      <PrivateRouter exact path="/mensalidades" component={Mensalidades} />
      <PrivateRouter exact path="/kexi-farma" component={KexiFarna} />
    </Switch>
  );
}
