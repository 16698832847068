import { all, takeLatest } from 'redux-saga/effects';

import { requestUser } from './user';

import { requestProduct, requestBackgroundProduct, requestBackgroundProductSemMsg, deleteProduct, deleteProductSemMsg } from './product';
import { requestClient } from './client';
import { requestPurshase } from './purshase';

import { Types as UserTypes } from '~/store/ducks/user';
import { Types as ProductTypes } from '~/store/ducks/product';
import { Types as ClientTypes } from '~/store/ducks/client';
import { Types as PurshaseTypes } from '~/store/ducks/purshase';


export default function* rootSaga() {
  yield all([
    takeLatest(UserTypes.GET_REQUEST_USER, requestUser),
    takeLatest(ProductTypes.GET_REQUEST_PRODUCT, requestProduct),
    takeLatest(ProductTypes.GET_REQUEST_BACKGROUND_PRODUCT, requestBackgroundProduct),
    takeLatest(ProductTypes.GET_REQUEST_BACKGROUND_PRODUCT_SEM_MSG, requestBackgroundProductSemMsg),
    takeLatest(ProductTypes.REQUEST_DELETE_PRODUCT, deleteProduct),
    takeLatest(ProductTypes.REQUEST_DELETE_PRODUCT_SEM_MSG, deleteProductSemMsg),
    

    takeLatest(ClientTypes.GET_REQUEST_CLIENT, requestClient),
    takeLatest(ClientTypes.GET_REQUEST_BACKGROUND_CLIENT, requestClient),

    takeLatest(PurshaseTypes.GET_REQUEST_PURSHASE, requestPurshase),
    takeLatest(PurshaseTypes.GET_REQUEST_BACKGROUND_PURSHASE, requestPurshase),
  ]);
}
