import { createGlobalStyle } from 'styled-components';

import 'antd/dist/antd.css';
import Product from '~/services/product';

const ButtonColor= Product.buttonColor;

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0 !important;
    box-sizing: border-box;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }

  #root {
    height: 100%;
    background: #f3f3f3;
  }

  html, body {
    min-height: 100%;
  }

  .ant-btn-primary {
    background-color: ${ButtonColor} !important;
    border-color: ${ButtonColor} !important;
  }

  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-submenu-active:hover,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-title,
  .ant-menu-horizontal > .ant-menu-submenu-title:hover,
  .ant-menu-horizontal > .ant-menu-hidden,
  .ant-menu-horizontal > .ant-menu-submenu-selected:hover,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: ${ButtonColor} !important;
    border-bottom: 2px solid ${ButtonColor};
  }

  .ant-menu-submenu-title:hover{
    color: ${ButtonColor}ed !important;
  }

  .ant-menu-vertical > .ant-menu-item:hover,
  .ant-menu-vertical > .ant-menu-submenu:hover,
  .ant-menu-vertical > .ant-menu-item-active,
  .ant-menu-vertical > .ant-menu-submenu-active,
  .ant-menu-vertical > .ant-menu-submenu-active:hover,
  .ant-menu-vertical > .ant-menu-item-open,
  .ant-menu-vertical > .ant-menu-submenu-open,
  .ant-menu-vertical > .ant-menu-item-selected,
  .ant-menu-vertical > .ant-menu-submenu-title,
  .ant-menu-vertical > .ant-menu-submenu-title:hover,
  .ant-menu-vertical > .ant-menu-hidden,
  .ant-menu-vertical > .ant-menu-submenu-selected:hover,
  .ant-menu-vertical > .ant-menu-submenu-selected {
    color: ${ButtonColor} !important;
  }


  .ant-tabs-tab:hover {
    color: ${ButtonColor} !important;
  }
  
  .ant-tabs-tab-active {
    color: ${ButtonColor} !important;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: ${ButtonColor};
    pointer-events: none;
  }

  .ant-steps-item-process .ant-steps-item-icon { 
    background: ${ButtonColor} !important; 
    border-color: ${ButtonColor} !important; 
  }
`;

export default GlobalStyle;
