import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import {
  Router,
} from 'react-router-dom';

import { ConfigProvider } from 'antd';
import PtBr from 'antd/es/locale/pt_BR';

import { createBrowserHistory } from 'history';

import GlobalStyle from '~/styles/global';
import Routes from './routes';

import './config/reactotron';

import store from './store';


const hist = createBrowserHistory();

// hist.listen((location, action) => {
//   console.log(location, action);
// });


function App() {
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);
  return (
    <>
      <ConfigProvider locale={PtBr}>
        <Provider store={store}>
          <Router history={hist}>
            <GlobalStyle />
            <Routes />
          </Router>
        </Provider>
      </ConfigProvider>
    </>
  );
}


export default App;
