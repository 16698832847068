import socket from 'socket.io-client';

let io;
function connection() {
  if (io === undefined) {
    io = socket('https://ecommerce.qesh.ai');
    //io = socket('http://localhost:3333');

    io.on('disconnect', () => {
      if (window.location.pathname === '/dashboard') {
        window.location.reload();
      }
    });

    io.on('connect', () => {

    });
  }
  return io;
}


export default connection;
