import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  baseURL: 'https://ecommerce.qesh.ai',
  // baseURL: 'http://localhost:3239',
});

export const kexiOnApi = axios.create({
  baseURL: 'https://charges.qesh.ai/credit-ecommerce',
  // baseURL: 'http://localhost:3330/credit-ecommerce',
  headers: {
    apiKey: 'bWtqamdoampoZmhnZ2ZoZmdkZnNraHQ2NWdnaGZo',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Connection: 'keep-alive',
  },
});

api.interceptors.request.use(async (config) => {
  const configParam = config;

  const token = getToken();

  configParam.headers.Authorization = `Bearer ${token}`;

  return configParam;
});

kexiOnApi.interceptors.request.use(async (config) => {
  const configParam = config;
  const token = getToken();
  configParam.headers.Authorization = `Bearer ${token}`;
  return configParam;
});

export default api;

export function loadLists() {
  return [
    {
      title: 'Clientes em compra',
      creatable: false,
      cards: [
        {
          id: 1,
          content: 'Estudar módulo 01 de NodeJS',
          labels: ['#7159c1'],
          // user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png',
        },
      ],
    },
    {
      title: 'Pedidos Recebidos',
      creatable: false,
      cards: [
        {
          id: 6,
          content: 'Recriando clone do Pipefy',
          labels: [],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png',
        },
      ],
    },
    {
      title: 'Enviados para entrega',
      creatable: false,
      cards: [
        {
          id: 7,
          content: 'Gravar sobre Geolocalização e mapas com React Native',
          labels: ['#7159c1'],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png',
        },
        {
          id: 8,
          content: 'Gravar testes e deploy ReactJS',
          labels: ['#54e1f7'],
          user: 'https://rocketseat-cdn.s3-sa-east-1.amazonaws.com/profile.png',
        },
        {
          id: 9,
          content: 'Ajustes na biblioteca unform',
          labels: [],
        },
      ],
    },
    {
      title: 'Problemas na entrega',
      creatable: false,
      done: false,
      cards: [
        {
          id: 10,
          content: 'Gravar aula sobre deploy e CI com React Native',
          labels: [],
        },
      ],
    },
    {
      title: 'Entregues',
      creatable: false,
      done: false,
      cards: [
        {
          id: 14,
          content: 'Gravar aula sobre deploy e CI com React Native',
          labels: [],
        },
      ],
    },
  ];
}
