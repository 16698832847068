import styled from 'styled-components';

export const Container = styled.div`
    height: 380px;
    border-radius: 20px;
`;

export const Header = styled.div`
  color: #000000;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
  border-radius: 8px;
  font-size: 16px;
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 600;
    font-size: 21px;
  }
`;

export const Content = styled.div`
  padding: 26px;
  text-align: center; 
  font-weight: 400 !important;
  font-size: 15px;
  min-height: 110px;

  display: flex;
  align-items: center;
  flex-direction: column;
  
  p {
    font-size: 22px;
    font-weight: bold;
    color: #313333;
    margin-bottom: 0;
  }

  span {
    margin-top: 56px;
    font-size: 20px;
    font-weight: bold;
  }
`;

export const CodeContainer = styled.div`
    width: 100%;
    margin-top: 36px;
    padding-left: 150px;
    padding-right: 150px;

    div {
        padding: 16px;
        border-radius: 8px;
        background-color: #C4C4C4;
        
        p {
            letter-spacing: 12px;
            font-size: 28px;
            color: #313333
        }
    }
`;
