import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  flex: 0 0 calc(100vw / 6.3);
  opacity: ${(props) => (props.done ? 0.6 : 1)};
  justify-content: center;
  position: relative;
  

  & + div {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }

  header {
    display: flex;
    justify-content: space-between;

    align-items: center;
    height: 42px;

    &.menus {
      background: #ffffff;
      border-radius: 4px;
      position: relative;
      z-index: 2;
      justify-content: center;
      margin: 0 auto;
    }

    &.menusWhite {
      background: #ffffff;
      border-radius: 4px;
      position: relative;
      z-index: 2;
      justify-content: center;
      margin: 0 auto;
    }

    
    h2 {
      font-weight: 500;
      font-size: 16px;
      padding: 0 10px;
    }

    button {
      width: 42px;
      height: 42px;
      border-radius: 18px;
      background: #3b5bfd;
      border: 0;
      cursor: pointer;
    }
  }

  ul {
    overflow: auto;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;
