/* eslint-disable react/prop-types */
import React, { useState, useRef, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';


import LoggiLogo from '../../../../assets/loggi.png';
import Manual from '../../../../assets/manual.png';


import BoardContext from '../Board/context';

import { Container, Label } from './styles';


export default function Card({
  data, index, listIndex, onClicked,
}) {
  const ref = useRef();
  const { move } = useContext(BoardContext);

  const [dataCard] = useState(() => {
    if (data && data.data && data.data.result
      && data.data.result.delivery_type) {
      return data.data.result.delivery_type;
    }

    return false;
  });

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'CARD', index, listIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item, monitor) {
      const draggedListIndex = item.listIndex;
      const targetListIndex = listIndex;

      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex && draggedListIndex === targetListIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = (targetSize.bottom - targetSize.top) / 2;

      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }

      move(draggedListIndex, targetListIndex, draggedIndex, targetIndex);

      item.index = targetIndex;
      item.listIndex = targetListIndex;
    },
  });

  dragRef(dropRef(ref));

  return (
    <Container
      onClick={(e) => onClicked(data)}
      ref={ref}
      isDragging={isDragging}
      style={{ opacity: data.content === 'invisible' ? 0 : 1 }}
    >
      <header>
        {data.labels.map((label) => <Label key={label} color={label} />)}
      </header>

      <p style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      >
        {data.content}

        <div>
          {dataCard && dataCard === 1 && (
          <img className="delivery loggi" src={LoggiLogo} alt="Loggi" />
          )}
          {dataCard && dataCard === 2 && (
          <img className="delivery manual" src={Manual} alt="Manual" />
          )}
        </div>
      </p>


      {data.value && <p>{data.value}</p>}
      {data.user && <img src={data.user} alt="" /> }
      {dataCard && dataCard === 1 && (
        <span style={{fontSize: 11}}>{'Status do parceiro: '+data.data.result.loggi_status}</span>
      )}
      </Container>
  );
}
