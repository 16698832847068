import styled from 'styled-components';

import { Layout } from 'antd';

import Product from '~/services/product';


export const Container = styled(Layout.Header)`
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  line-height: inherit;
  min-height: 95px;

  /* background: #42c1c7; */
  background: ${Product.headerColor};
  color: #FFFFFF;

  border-bottom: 1px solid #ffffff;
  box-shadow: 0 5px 5px -5px #FFFFFF;

  .ant-badge-count {
    background: #2db7f5;
  }
`;

export const InfoRight = styled.div`
  display: flex;

  align-items: center;
`;

export const Saldo = styled.div`
  margin-right: 30px;
`;

export const Notification = styled.div`
  background: #FFFFFF;
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  cursor: pointer;
`;
