/* eslint-disable react/prop-types */
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


import Board from './components/Board';

function Trello({ data }) {
  return (
    <DndProvider backend={HTML5Backend}>
      <Board data={data} />
    </DndProvider>
  );
}

export default Trello;
