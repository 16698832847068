import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';

import { notification } from 'antd';

import socket from '~/services/notification';

import { Creators as ActionUser } from '~/store/ducks/user';


export default function GlobalUser({ children }) {
  const { checkedUser, loading, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  if (!checkedUser && !loading) {
    dispatch(ActionUser.getUser());
  }


  useEffect(() => {
    if (user.id) {
      const io = socket();
      io.emit('room', user.id);

      io.on('REMOVE_CART', ({ product }) => {
        notification.info({
          message: `[${product.removeQuantity}] ${product.name} removido do carrinho`,
          placement: 'bottomRight',
        });
      });

      io.on('ADD_CART', ({ product }) => {
        notification.info({
          message: `[${product.addQuantity}] ${product.name} adicionado ao carrinho`,
          placement: 'bottomRight',
        });
      });
    }
  }, [user.id]);


  return (
    <>
      {!checkedUser && loading ? (
        <div style={{
          display: 'flex', flex: 1, background: '#f4f5f8', height: '100vh',
        }}
        >
          Loading...
        </div>
      ) : (
        <>
          {user && user.id && (
            <>
              {children}
            </>
          )}
        </>
      )}
    </>
  );
}

GlobalUser.propTypes = {
  children: PropTypes.node.isRequired,
};
