import React from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  Container, Header, Content, CodeContainer,
} from './styles';
import { HandleFormatMoney } from '~/services/utils';

const CodeModal = ({
  visible, hidden, code, value,
}) => (
  <Modal
    wrapClassName="code-modal"
    centered
    visible={visible}
    onOk={hidden}
    closable={false}
    onCancel={hidden}
    footer={false}
    destroyOnClose
    style={{ padding: 0, margin: 0, borderRadius: '16px' }}
    bodyStyle={{ padding: 16 }}
    width={660}
  >
    <Container>
      <Header>
        <CloseOutlined onClick={hidden} />
      </Header>
      <Content>
        <p>Informe ao cliente o código abaixo.</p>

        <CodeContainer>
          <div>
            <p>{code}</p>
          </div>
        </CodeContainer>

        <span>
          Valor a ser cobrado: <span style={{ color: '#1be61e', fontSize: '22px' }}>R$ {HandleFormatMoney(value)}</span>
        </span>
      </Content>
    </Container>
  </Modal>
);


CodeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hidden: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};


export default CodeModal;
