const dominioVilla = window.location.host.match(/villapay/g) || window.location.host.match(/villa/g);
const dominioCDL = window.location.host.match(/cdl/g) || window.location.host.match(/CDL/g);

const product = () => {
  if (dominioVilla && dominioVilla.length > 0) {
    return {
      name: 'Villa Pay',
      logo: 'https://app.qesh.ai/villa.png',
      headerColor: '#005438',
      buttonColor: '#005437',
      logoHome: 'https://app.qesh.ai/villa.png',
    };
  }

  if (dominioCDL && dominioCDL.length > 0) {
    return {
      name: 'CDL',
      logo: 'https://cdlroo.qesh.ai/static/media/logo-cdl.05716fc0.png',
      headerColor: '#636466',
      buttonColor: '#2B4F81',
      logoHome: 'https://cdlroo.qesh.ai/static/media/logo-cdl.05716fc0.png',
    };
  }

  return {
    name: 'Qesh',
    logo: require('../assets/logo_qesh.png'),
    headerColor: '#636466',
    // buttonColor: '#42c1c7',
    buttonColor: '#2B4F81',
    logoHome: 'https://app.qesh.ai/logo_qesh.png',
  };
};

export default product();
