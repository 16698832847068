import { call, put } from 'redux-saga/effects';
import api from '~/services/api';

import { Creators as UserAction } from '~/store/ducks/user';

export function* requestUser() {
  try {
    const { data } = yield call(api.get, 'users');

    yield put(UserAction.getSucessUser(data));
  } catch (err) {
    window.location.href = '/logout';
  }
}
