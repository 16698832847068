import styled from 'styled-components';

import { Row } from '~/styles/components';

export const Container = styled(Row)`
  flex-direction: column;
  /* width: 100%; */
  flex: 1;
  
`;
