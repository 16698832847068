import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Skeleton, Table, Typography,
} from 'antd';

import { Container } from './styles';
import { Col } from '~/styles/components';


import { Creators as ClientCreators } from '~/store/ducks/client';

import socket from '~/services/notification';

import { HandleFormatMoney } from '~/services/utils';


export default function NovoProdutoLote() {
  const dispatch = useDispatch();
  const io = socket();
  const { clients, loading } = useSelector((state) => state.client);
  const [dataSource, setDataSouce] = useState([]);


  useEffect(() => {
    dispatch(ClientCreators.getRequestClient());
    io.on('REMOVE_CART', () => {
      dispatch(ClientCreators.getRequestBackgroundClient());
    });
    io.on('ADD_CART', () => {
      dispatch(ClientCreators.getRequestBackgroundClient());
    });
    io.on('PURSHASE', () => {
      dispatch(ClientCreators.getRequestBackgroundClient());
    });
  }, [dispatch, io]);

  useEffect(() => {
    if (clients.length) {
      const source = clients.map((client, index) => ({
        key: index,
        id: client.id,
        name: client.name || '-',
        phone: client.phone,
        email: client.email || '-',
        cart: client.cart || false,
        in_shopping_value: `R$ ${HandleFormatMoney(client.cart?.products.total || 0.0)}`,
        in_shopping: (!!(client.cart && client.cart?.products.items.length > 0)),
      })).filter((item) => item.in_shopping);

      setDataSouce(source);
    }
  }, [clients]);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: 'Total',
      dataIndex: 'in_shopping_value',
      key: 'in_shopping_value',
      sorter: (a, b) => (a.in_shopping_value < b.in_shopping_value ? -1 : 1),
      render: (text, client) => (
        <span style={{
          color: client.in_shopping ? 'green' : 'inherit',
          fontWeight: client.in_shopping ? 'bold' : 'normal',
        }}
        >{text}
        </span>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Typography.Title level={3}>Vendas em andamento</Typography.Title>
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Skeleton active paragraph={{ rows: 8 }} loading={loading}>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{ position: ['topRight', 'bottomRight'] }}
              scroll={{ y: 400, scrollToFirstRowOnChange: true }}
              expandable={{
                expandedRowRender: (client) => (
                  <div style={{
                    margin: 0,
                  }}
                  >
                    {client.cart && client.cart.products.total > 0 ? (
                      <>
                        {client.cart.products.items.map((item) => (
                          <div key={item.id}>
                            <strong>Produto: </strong>{item.name}{' | '}
                            <strong>Preço: </strong>R$ {HandleFormatMoney(item.price)}{' | '}
                            <strong>Quantidade: </strong>{item.quantity}
                          </div>
                        ))}
                        <div style={{ marginTop: 10 }}>
                          <strong>Total: </strong>
                          R$ {HandleFormatMoney(client.cart.products.total)}
                        </div>
                      </>
                    ) : (
                      <div>
                        Cliente não esta em compras
                      </div>
                    )}
                  </div>
                ),
                rowExpandable: (record) => record.name !== 'Not Expandable',
              }}

            />
          </Skeleton>
        </Col>
      </Container>
    </>
  );
}
