import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Menu as MenuAntd, Switch, Row, Col, Tooltip,
} from 'antd';
import {
  DashboardOutlined, DatabaseOutlined, DollarCircleOutlined, TeamOutlined, SwitcherOutlined, CloseOutlined, SoundOutlined, CheckOutlined,
} from '@ant-design/icons';

import { FaBriefcaseMedical } from 'react-icons/fa';

import Axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Container } from './styles';

import foneBlock from '../../assets/img/fone.png';

import Product from '~/services/product';

const { SubMenu } = MenuAntd;

export default function Menu({ setSound }) {
  const { user } = useSelector((state) => state.user);

  const [selected, setSelected] = useState('/dashboard');
  const history = useHistory();
  const [toltip, setToltip] = useState('Som ativado!');
  const [backgroundButton, setBackgroundButton] = useState(Product.buttonColor);
  const [startSound, setStartSound] = useState(true);
  const [cashbackCount, setCashbackCount] = useState(null);
  const [cashbackGoal, setCashbackGoal] = useState(null);

  useEffect(() => {
    setSelected(history.location.pathname);
    if (startSound) {
      localStorage.setItem('sound', true);
      setStartSound(false);
    }
  }, [history.location.pathname]);

  function handleClick(e) {
    history.push(`${e.key}`);
    setSelected(e.key);
  }

  const handleSound = () => {
    const statusSound = localStorage.getItem('sound');
    if (statusSound === true || statusSound === 'true') {
      localStorage.setItem('sound', false);
      setToltip('Som desativado!');
      setBackgroundButton('#9a9a9a');
    } else {
      localStorage.setItem('sound', true);
      setToltip('Som ativado!');
      setBackgroundButton(Product.buttonColor);
    }
  };

  const updateCashbackValues = () => {
    Axios.get(`https://cashback.qesh.ai/cashback/${user.product_name}/balance/count?start=${moment().startOf('month').toISOString()}&end=${moment().endOf('month').toISOString()}&shop_id=${user.id}`, {
      headers: {
        admin: '6daaa86c2472418ce8b4a8e8790d8c1e',
      },
    }).then((res) => {
      setCashbackCount(res.data.count);
    }).catch((err) => console.log(err));

    Axios.get(`https://cashback.qesh.ai/cashback-dates/${user.product_name}`, {
      headers: {
        admin: '6daaa86c2472418ce8b4a8e8790d8c1e',
      },
    }).then((res) => {
      setCashbackGoal(res.data?.data?.goal);
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    updateCashbackValues();
    setInterval(updateCashbackValues, 30000);
  }, []);

  return (
    <Container>
      <Row>
        <Col
          span={6}
          push={18}
          style={{
            alignItems: 'center', justifyContent: 'center', display: 'flex', position: 'right',
          }}
        >
          <Tooltip title={toltip}>
            <Switch
              onClick={() => handleSound()}
              style={{ background: backgroundButton, marginRight: -200, paddingLeft: 3 }}
              checkedChildren={<SoundOutlined />}
              unCheckedChildren={<img style={{ widht: 10, height: 20 }} src={foneBlock} />}
              defaultChecked
            />
          </Tooltip>
        </Col>
        <Col span={18} pull={6}>
          <MenuAntd style={{ display: 'flex', alignItems: 'center' }} onClick={handleClick} selectedKeys={[selected]} mode="horizontal">


            <MenuAntd.Item key="/dashboard">
              <DashboardOutlined />
              Dashboard
            </MenuAntd.Item>
            <SubMenu
              title={(
                <>
                  <DatabaseOutlined />
                  Estoque
                </>
              )}
            >
              {/* <MenuAntd.ItemGroup title="Item 1"> */}
              <MenuAntd.Item key="/estoque">Gerenciar Estoque</MenuAntd.Item>
              <MenuAntd.Item key="/estoque/novo-produto">Cadastrar Produto</MenuAntd.Item>
              <MenuAntd.Item key="/estoque/novo-produto-lote">Cadastrar Produto em Lote</MenuAntd.Item>
              <MenuAntd.Item key="/departamentos">Cadastrar Departamentos</MenuAntd.Item>
              {/* </MenuAntd.ItemGroup> */}
            </SubMenu>
            <SubMenu
              title={(
                <>
                  <DollarCircleOutlined />
                  Vendas
                </>
              )}
            >
              <MenuAntd.Item key="/vendas/avulsa">Cadastrar venda avulsa</MenuAntd.Item>
              <MenuAntd.Item key="/vendas/externa">Cadastrar venda externa</MenuAntd.Item>
              <MenuAntd.Item key="/vendas/em-andamento">Vendas em andamento</MenuAntd.Item>
              <MenuAntd.Item key="/vendas/concluida">Vendas concluídas</MenuAntd.Item>
            </SubMenu>
            <SubMenu
              title={(
                <>
                  <TeamOutlined />
                  Clientes
                </>
              )}
            >
              <MenuAntd.Item key="/clientes">Clientes</MenuAntd.Item>
              {/* <MenuAntd.Item key="/clientes/novo-cliente-lote">Cadastrar Clientes em Lote</MenuAntd.Item> */}
            </SubMenu>

            <MenuAntd.Item key="/ceps">
              <SwitcherOutlined />
              CEPS
            </MenuAntd.Item>

            <SubMenu
              title={(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <DollarCircleOutlined />
                  <span>Cashback</span>
                  { cashbackCount !== null && cashbackGoal !== null && (
                    <div
                      onClick={() => history.push('/mensalidades')}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 50,
                        marginLeft: 6,
                        marginTop: 5,
                        backgroundColor: +cashbackCount >= +cashbackGoal ? 'green' : 'rgba(255,34,0,0.9)',
                        width: 35,
                        height: 35,
                      }}
                    >
                      <span style={{ fontSize: 12, color: 'white' }}>{cashbackCount}/{cashbackGoal}</span>
                    </div>
                  ) }

                </div>
              )}
            >
              <MenuAntd.Item key="/cashback">Consultar Cashback</MenuAntd.Item>
              <MenuAntd.Item key="/cashback/extrato">Extrato Cashback</MenuAntd.Item>

              {/* <MenuAntd.Item key="/clientes">Clientes</MenuAntd.Item> */}
              {/* <MenuAntd.Item key="/clientes/novo-cliente-lote">Cadastrar Clientes em Lote</MenuAntd.Item> */}
            </SubMenu>
            {user && user.product_name === 'kexion' && (
            <MenuAntd.Item key="/kexi-farma">
              <FaBriefcaseMedical style={{ marginRight: 8 }} />
              Kéxi Farma
            </MenuAntd.Item>
            )}

          </MenuAntd>
        </Col>
      </Row>
    </Container>
  );
}
