import styled from 'styled-components';
import { Button } from 'antd';
import CurrencyInput from 'react-currency-input';
import { Row } from '~/styles/components';

export const Container = styled(Row)`
  
`;

export const Content = styled.div`
    width: 100%;
    
    padding-top: 32px;
    padding-left: 20%;
    padding-right: 20%;
    
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
`;

export const Input = styled(CurrencyInput)`
    margin-top: 86px;
    border: 1px solid #2B4F81;
    border-radius: 8px;
    padding: 6px;
    width: 100%;
    max-width: 550px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    
    padding-left: 20%;
    padding-right: 20%;
    margin-top: 86px;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    max-width: 200px;
    background-color: #0066B0;
    border-radius: 8px;
    color: #fff;
    height: 36px;

    :hover {
        background-color: #0072c4;
        color: #fff
    }
`;
