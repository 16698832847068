/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import {
  Typography, Tooltip, Upload, Button, message, Steps, Divider, Result, Radio,
} from 'antd';
import { IoIosHelpCircle } from 'react-icons/io';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Container, radioStyle } from './styles';

import { getToken } from '~/services/auth';
import { HandleMoneyToFloat, HandleFormatMoney, typeNumberInt } from '~/services/utils';


import {
  RowGutter, Col, RawInput, Label, SelectInput,
} from '~/styles/components';

import api from '~/services/api';

const { Step } = Steps;
const { Dragger } = Upload;


export default function Main() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [product, setProduct] = useState({});
  const [error, setError] = useState({ field: '', message: '' });
  const [currentProduct, setCurrentProduct] = useState({});
  const [radio, setRadio] = React.useState(1);

  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    api.get('products/user/departments').then(({ data }) => {
      const newData = data.map((d) => ({
        id: d.id,
        value: d.name,
        text: d.name,
      }));

      setDepartments(newData);
    });
  }, []);

  function handleChange(value) {
    setProduct({
      ...product,
      department: value,
    });
  }


  function handleInputProduct(e) {
    const { name, value } = e.target;

    setProduct({
      ...product,
      [name]: value,
    });
  }


  async function nextStepProduct(e) {
    e.preventDefault();
    const requiredFields = ['identifier', 'name', 'quantity', 'price', 'department'];
    setError({ field: '', message: '' });

    for (let i = 0; i < requiredFields.length; i += 1) {
      const current = requiredFields[i];

      if (!product[current]) {
        setError({ field: current, message: 'Este campo não pode ficar em branco' });
        message.error('Há erros no formulário, por favor corrija e tente novamente');
        return;
      }

      if (!product.department) {
        setError({ field: 'department', message: 'Selecione um departamento' });
        message.error('Há erros no formulário, por favor corrija e tente novamente');
        return;
      }

      if (product.quantity < 0) {
        setError({ field: 'quantity', message: 'A quantidade não pode ser menor que 0' });
        message.error('Há erros no formulário, por favor corrija e tente novamente');
        return;
      }

      if (!typeNumberInt(product.quantity)) {
        setError({ field: 'quantity', message: 'A quantidade deve ser um número inteiro' });
        message.error('Há erros no formulário, por favor corrija e tente novamente');
        return;
      }

      if (product.price < 0) {
        setError({ field: 'price', message: 'O valor do produto não pode ser menor que 0' });
        message.error('Há erros no formulário, por favor corrija e tente novamente');
        return;
      }
    }

    setLoading(true);
    try {
      const value = HandleMoneyToFloat(HandleFormatMoney(
        Number(product.price) - Number(product.cashback || 0),
      ));
      const cb = HandleMoneyToFloat(HandleFormatMoney(
        radio === 1 ? (product.cashback || 0) : (+product.cashback * +product.price / 100),
      ));
      const { data: currentProductData } = await api.post('/products', {
        ...product,
        price: Number(value) + Number(cb),
        cashback: cb,
      });

      setCurrentProduct(currentProductData);
      setStep(step + 1);
      // setStep(step + 2); // ocultar imagem
      setLoading(false);
    } catch (err) {
      message.error('Não foi possível cadastrar o produto');
      setLoading(false);
    }
  }


  const props = {
    name: 'image',
    multiple: false,
    action: `https://ecommerce.qesh.ai/products/${currentProduct.id}/image`,
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} cadastrada com sucesso!`);
        setStep(step + 1);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} erro ao fazer upload.`);
      }
    },
  };


  const options = departments.map((d) => <SelectInput.Option key={d.value}>{d.text}</SelectInput.Option>);

  return (
    <>
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Typography.Title level={3}>Novo Produto</Typography.Title>
        </Col>


        <Col md={{ span: 24 }} lg={{ span: 24 }} style={{ background: '#FFFFFF', padding: 20, borderRadius: 4 }}>
          <Steps current={step}>
            <Step title="Dados básicos" description="" />
            <Step title="Imagem" subTitle="" description="" />
            <Step title="Concluído" description="" />
          </Steps>

          <Divider />

          {step === 0 && (
            <>
              <form onSubmit={nextStepProduct}>
                <RowGutter>
                  <Col lg={{ span: 8 }}>
                    <div>
                      <Label>Identificador
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Identificador para controle interno"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <RawInput
                        placeholder="#401020"
                        name="identifier"
                        value={product.identifier}
                        onChange={(e) => handleInputProduct(e)}
                      />
                      {error && error.field === 'identifier' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 8 }}>
                    <div>
                      <Label>Nome
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Nome do produto. É o nome que vai aparecer para o cliente"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <RawInput
                        placeholder="Arroz do tipo 1"
                        name="name"
                        value={product.name}
                        onChange={handleInputProduct}
                      />
                      {error && error.field === 'name' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 8 }}>
                    <div>
                      <Label>Departamento
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Este campo ajuda a direcionar o cliente,
                    agrupando produtos por departamentos"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <SelectInput
                        name="department"
                        placeholder="Selecione..."
                        defaultActiveFirstOption={false}
                        onChange={handleChange}
                        showArrow
                        notFoundContent={null}
                        showSearch
                      >
                        {options}
                      </SelectInput>
                      {error && error.field === 'department' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                </RowGutter>
                <RowGutter>
                  <Col lg={{ span: 8 }}>
                    <div>
                      <Label>Quantidade em estoque
                        <Tooltip arrowPointAtCenter placement="topLeft" title="Quantidade disponível em estoque">
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <RawInput
                        min="0"
                        placeholder="130"
                        name="quantity"
                        type="number"
                        value={product.quantity}
                        onChange={handleInputProduct}
                      />
                      {error && error.field === 'quantity' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 8 }}>
                    <div>
                      <Label>Preço total
                        <Tooltip arrowPointAtCenter placement="topLeft" title="Preço total do produto. Esse valor deve ter incluso o valor de cashback, ou seja, o valor que o cliente irá pagar.">
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <RawInput
                        name="price"
                        type="number"
                        min="0"
                        placeholder="R$ 10,90"
                        value={product.price}
                        onChange={handleInputProduct}
                      />
                      {error && error.field === 'price' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 8 }}>
                    <div>
                      <Label>Cash back
                        <Tooltip arrowPointAtCenter placement="topLeft" title="Cash back unitário do produto">
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <RawInput
                        name="cashback"
                        type="number"
                        min="0"
                        placeholder={radio === 1 ? 'R$ 0,10' : ' 10%'}
                        // placeholder="R$ 0,90"
                        value={product.cashback}
                        onChange={handleInputProduct}
                      />

                      <Radio.Group style={{ display: 'flex', justifyContent: 'center' }} onChange={(e) => setRadio(e.target.value)} value={radio}>
                        <Radio style={radioStyle} value={1}>Valor real</Radio>
                        <Radio style={radioStyle} value={2}>Porcentagem</Radio>
                      </Radio.Group>
                      {error && error.field === 'cashback' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                    <span style={{ margin: 10, marginLeft: '22%' }}>Cashback calculado: R$ {HandleFormatMoney(radio === 1 ? product.cashback : (+product.cashback * +product.price / 100))}</span>
                  </Col>
                </RowGutter>
                <RowGutter>
                  <Col lg={{ span: 24 }}>
                    <div>
                      <Label>Descrição
                        <Tooltip arrowPointAtCenter placement="topLeft" title="Descrição do produto">
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <RawInput
                        placeholder=""
                        name="description"
                        value={product.description}
                        onChange={handleInputProduct}
                      />
                      {error && error.field === 'description' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <div style={{ marginTop: 30 }}>
                      <Button htmlType="submit" type="primary" block size="large" onClick={nextStepProduct}>
                        {loading && <LoadingOutlined />}
                        {/* Próximo passo */}
                        Cadastrar
                      </Button>
                    </div>
                  </Col>
                </RowGutter>
              </form>
            </>
          )}

          {step === 1 && (
          <>
            <form>
              <RowGutter>
                <Col style={{ paddingBottom: 30 }}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Clique ou arraste a imagem para esta área para fazer o upload</p>
                    <p className="ant-upload-hint" />
                  </Dragger>
                </Col>
              </RowGutter>

              <RowGutter>
                <Col md={{ span: 24 }} style={{ textAlign: 'center' }}>
                  OU
                </Col>
                <Col md={{ span: 24 }} style={{ marginTop: 30 }}>
                  <Button block type="primary" size="large" onClick={() => setStep(step + 1)}>
                    Clique aqui para prosseguir sem imagem
                  </Button>
                </Col>
              </RowGutter>
            </form>
          </>
          ) }
          {step === 2 && (
            <>
              <Result
                status="success"
                title="Produto cadastrado com sucesso!"
                subTitle="Confira o produto cadastrado"
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      setProduct({});
                      setCurrentProduct({});
                      setError({ field: '', message: '' });
                      setStep(0);
                    }}
                  >
                    Cadastrar novo produto
                  </Button>,
                  <Button key="buy" onClick={() => history.push('/estoque')}>Ver estoque</Button>,
                ]}
              />
            </>
          )}
        </Col>
      </Container>
    </>
  );
}
