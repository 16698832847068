import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 30px 0;
  height: calc(100vh - 250px);

  overflow-y: hidden;
  overflow-x: auto;
`;
