/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import {
  Map, GoogleApiWrapper, Marker,
} from 'google-maps-react';

import {
  Modal, Result, Button,
} from 'antd';


import axios from 'axios';

import { SmileOutlined } from '@ant-design/icons';
import api from '~/services/api';
import Self from '../../assets/people.png';


import {
  RawInput, Label,
} from '~/styles/components';


import { Container } from './styles';

const urlGeolocation = `https://maps.googleapis.com/maps/api/geocode/
json?&address=30530-280&key=AIzaSyAuQPv4tnJ2C7mS5v9mnfK03wtHL3iOpjw`;


function Mapa({ google }) {
  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  const [zoom, setZoom] = useState(11);

  const [errorCep, setErrorCep] = useState('');
  const [validCep, setValidCep] = useState(false);
  const [visible, setVisile] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPlace, setCurrentPlace] = useState({});
  const [cep, setCep] = useState(() => {
    const hasCep = localStorage.getItem('@qeshecommerce-cep');
    if (hasCep) {
      setValidCep(true);
      return hasCep;
    }
    return '';
  });
  const [geoLocation, setGeoLocation] = useState(() => {
    const location = localStorage.getItem('@qeshecommerce-geo');

    if (location) {
      return JSON.parse(location);
    }
    return false;
  });

  function getLocationByCep() {
    if (!geoLocation) {
      axios.get(urlGeolocation).then(({ data }) => {
        if (data.status === 'OK') {
          const [results] = data.results;
          const { geometry: { location } } = results;

          localStorage.setItem('@qeshecommerce-geo', JSON.stringify(location));
          setGeoLocation(location);
        } else {
          alert('Não conseguimos determinar sua região');
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(({ coords }) => {
        localStorage.setItem('@qeshecommerce-geo', JSON.stringify({
          lat: coords.latitude,
          lng: coords.longitude,
        }));
        setGeoLocation({
          lat: coords.latitude,
          lng: coords.longitude,
        });
      }, () => {
        getLocationByCep();
      });
    } else {
      //
    }
  }

  async function handleMarkers() {
    setErrorCep('');
    const newCep = cep.replace('-', '');

    const isValid = newCep.length && newCep.length === 8;
    if (!isValid) {
      setErrorCep('CEP invalido');
      setMarkers([]);
      setValidCep(false);
      return;
    }

    setCep(newCep);
    localStorage.setItem('@qeshecommerce-cep', newCep);

    try {
      setLoading(true);
      const { data } = await api.get(`ceps?cep=${newCep}`);

      if (!data.length) {
        setErrorCep('Não conseguimos atender sua região');
        localStorage.removeItem('@qeshecommerce-cep');
        setLoading(false);
        return;
      }

      setMarkers(data);
      setValidCep(true);
      setLoading(false);
    } catch (err) {
      setErrorCep('Não conseguimos atender sua região');
      localStorage.removeItem('@qeshecommerce-cep');
      setLoading(false);
    }
  }

  useEffect(() => {
    getLocation();
    if (cep) {
      handleMarkers();
    }
    const width = window.innerWidth;

    if (width < 600) {
      setZoom(10);
    }
  }, [cep]);

  useEffect(() => {
    getLocation();
  }, [geoLocation]);


  function onMarkerClick({ id }) {
    if (id) {
      setVisile(true);
      const estabelecimento = markers.find((m) => m.id === id);
      setCurrentPlace(estabelecimento);
    }
  }

  function onClose() {
    setVisile(false);
  }


  return (
    <Container>
      {!validCep ? (
        <div>
          <Result
            status="info"
            icon={<SmileOutlined />}
            title="Informe seu CEP para começar"
          >
            <div className="desc">
              <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto' }}>
                <Label>CEP</Label>
                <RawInput
                  placeholder="0000-000"
                  name="cep"
                  value={cep}
                  onChange={(e) => setCep(e.target.value)}
                />
                <div style={{ marginTop: 5, textAlign: 'center', color: 'red' }}>{errorCep}</div>
                {loading && (
                  <div>
                    Buscando estabelecimentos...
                  </div>
                )}
                <Button
                  style={{ marginTop: 15 }}
                  htmlType="submit"
                  type="primary"
                  block
                  size="large"
                  onClick={handleMarkers}
                >
                  Continuar
                </Button>
              </div>

            </div>
          </Result>
        </div>
      ) : (
        <>
          <div style={{
            position: 'absolute',
            width: '250px',
            background: 'rgba(255,255,255,0.8)',
            zIndex: 2,
            top: 70,
            left: 15,
            padding: 20,
            borderRadius: 8,
            color: '#333333',
          }}
          >

            <div style={{ marginBottom: 10 }}>
              Você esta comprando para o CEP: {cep}
            </div>

            <Button
              type="primary"
              onClick={() => {
                setCep('');
                setValidCep(false);
                localStorage.removeItem('@qeshecommerce-cep');
              }}
            >Mudar meu endereço
            </Button>
          </div>
          {geoLocation && (
          <Map
            google={google}
            zoom={zoom}
            style={mapStyles}
            initialCenter={{ ...geoLocation }}
          >
            <Marker
              onClick={onMarkerClick}
              name=""
              // icon={Self}
            />

            {markers.map((m) => (
              <Marker
                key={m.id}
                onClick={onMarkerClick}
                name={m.name}
                id={m.id}
                position={{ lat: m.lat, lng: m.lng }}
                icon={m.avatar}
                // icon={new window.google.maps.MarkerImage(m.avatar,
                //   null, null, null, new window.google.maps.Size(64, 64))}
              />
            ))}
          </Map>
          )}

          {/* <Drawer
            visible={visible}
            title={currentPlace.name}
            placement="right"
            width={window.innerWidth < 600 ? '80%' : '50%'}
            closable
            onClose={onClose}
          >
            <form onSubmit={(e) => {
              e.preventDefault();
              window.location.href = `https://api.whatsapp.com/send?phone=5517992604996&text=u::${currentPlace.id}`;
            }}
            >

              <h3>Você está comprando no {currentPlace.name}</h3>
              <br />

              <Button htmlType="submit" type="primary" block size="large">Comprar Online</Button>
            </form>
          </Drawer> */}

          <Modal
            style={{ textAlign: 'center' }}
            width={320}
            title={currentPlace.name}
            visible={visible}
            onOk={() => {}}
            onCancel={onClose}
            footer={[
              <Button key="back" onClick={onClose}>
                Voltar para o Mapa
              </Button>,
              // <Button key="submit" type="primary" loading={loading} onClick={() => {}}>
              //   Comprar Online
              // </Button>,
            ]}
          >
            <div>
              <img style={{ borderRadius: '4px' }} src={currentPlace.logo} alt="Estabelecimento" width="64" />
              <h2>{currentPlace.name}</h2>

              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={() => {
                  window.location.href = `https://api.whatsapp.com/send?phone=553199390826&text=Menu::${currentPlace.name}`;
                }}
              >
                Comprar Online
              </Button>
            </div>
          </Modal>
        </>
      )}
    </Container>
  );
}


export default GoogleApiWrapper({
  apiKey: 'AIzaSyAuQPv4tnJ2C7mS5v9mnfK03wtHL3iOpjw',
})(Mapa);
