import React, { useState, useEffect } from 'react';

import {
  Typography, Tooltip, Button, message, Tag, Modal,
} from 'antd';
import { IoIosHelpCircle } from 'react-icons/io';
import { LoadingOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { Container } from './styles';

import {
  RowGutter, Col, RawInput, Label,
} from '~/styles/components';

import api from '~/services/api';

const { confirm } = Modal;

export default function Main() {
  const [loading, setLoading] = useState(false);

  const [department, setDepartment] = useState('');
  const [error, setError] = useState('');
  const [departments, setDepartments] = useState([]);

  async function nextStepProduct(e) {
    e.preventDefault();
    setError('');

    if (!department.length) {
      setError('Departamento é obrigatório');
      return;
    }


    setLoading(true);
    try {
      await api.post('products/user/departments', {
        department,
      });
      api.get('products/user/departments').then(({ data }) => setDepartments(data));
      message.success('Departamento cadastrado com sucesso');


      setLoading(false);
    } catch (err) {
      message.error('Não foi possível cadastrar o departmento');
      setLoading(false);
    }
  }

  async function handleDelete(id) {
    const del = await api.delete(`products/user/departments/${id}`);
    message.success('Departamento deletado com sucesso');

    api.get('products/user/departments').then(({ data }) => setDepartments(data));
  }

  useEffect(() => {
    api.get('products/user/departments').then(({ data }) => setDepartments(data));
  }, []);

  useEffect(() => {
    api.get('products/user/departments').then(({ data }) => setDepartments(data));
  }, [departments]);

  async function showDeleteConfirm(id) {
    confirm({
      title: 'Deseja continuar?',
      icon: <ExclamationCircleOutlined />,
      content: 'Os produtos desse departamento serão excluídos',
      okText: 'Ok, quero excluir',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        handleDelete(id);
      },
      onCancel() {
      },
    });
  }


  return (
    <>
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Typography.Title level={3}>Novo Departamento</Typography.Title>
        </Col>

        <Col md={{ span: 24 }} lg={{ span: 24 }} style={{ background: '#FFFFFF', padding: 20, borderRadius: 4 }}>
          <form onSubmit={nextStepProduct}>
            <RowGutter>
              <Col lg={{ span: 8 }}>
                <div>
                  <Label>DEPARTAMENTO
                    <Tooltip
                      arrowPointAtCenter
                      placement="topLeft"
                      title="Informe todos os nomes do departamento"
                    >
                      <IoIosHelpCircle />
                    </Tooltip>
                  </Label>
                  <RawInput
                    // placeholder="#401020"
                    maxLength="40"
                    name=""
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                  />

                  {!!error && (
                  <div style={{ marginTop: 5, color: 'red', textAlign: 'center' }}>
                    {error}
                  </div>
                  )}
                </div>
              </Col>
              <Col lg={{ span: 4 }}>
                <div style={{ marginTop: 30 }}>
                  <Button htmlType="submit" type="primary" block size="large" onClick={nextStepProduct}>
                    {loading && <LoadingOutlined />}
                    Cadastrar Departamento
                  </Button>
                </div>
              </Col>
            </RowGutter>
          </form>
        </Col>

        <Col
          md={{ span: 24 }}
          lg={{ span: 24 }}
          style={{
            marginTop: 20, background: '#FFFFFF', padding: 20, borderRadius: 4,
          }}
        >
          <h3>Departamentos cadastrados</h3>

          <div style={{ width: '650px' }}>
            {departments.map((cp) => (
              <span
                key={cp.id}
                style={{
                  display: 'inline-block', marginRight: 10, width: 300, marginBottom: 5,
                }}
              >
                {cp.name}
                <Tag onClick={() => showDeleteConfirm(cp.id)} style={{ cursor: 'pointer' }} color="error"><CloseCircleOutlined /></Tag>
              </span>
            ))}
          </div>
        </Col>
      </Container>
    </>
  );
}
