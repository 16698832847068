import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import {
  Typography, Table, notification, Pagination, DatePicker, Button, Spin,
} from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {
  Container, Content, Input, ButtonContainer, StyledButton,
} from './styles';
import { kexiOnApi } from '../../services/api';
import CodeModal from './CodeModal';
import { HandleFormatMoney } from '~/services/utils';
import { create as downloadSalesReport } from './reports/SalesReport';

const KexiFarna = () => {
  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Data da venda',
      dataIndex: 'paid_at',
      key: 'paid_at',
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Situação',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Nome do cliente',
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: 'Data do vencimento',
      dataIndex: 'credit_due_at',
      key: 'credit_due_at',
    },
    {
      title: 'CPF do cliente',
      dataIndex: 'customer_document',
      key: 'customer_document',
    },
    {
      title: 'Número do telefone',
      dataIndex: 'customer_phone',
      key: 'customer_phone',
    },
  ];

  const [value, setValue] = useState();
  const [valueError, setValueError] = useState('');
  const [openCodeModal, setOpenCodeModal] = useState(false);
  const [code, setCode] = useState('');
  const [sales, setSales] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [, setTotalItemsPage] = useState(0);
  const [selectedDay, setSelectedDay] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(null);

  const loadSales = useCallback((page = 1) => {
    kexiOnApi.get('/sales', {
      params: {
        page: page ? page - 1 : 0,
        ...(selectedDay && { date: selectedDay.format('YYYY-MM-DDT03:00:00Z') }),
      },
    }).then((response) => {
      setSales(response.data.sales);

      setCurrentPage(page ? page - 1 : 0);
      setTotalPages(response.data.total_pages + 1);
      setTotalItems(response.data.total_items);
      setTotalItemsPage(response.data.total_items_page);
    }).catch(() => notification.error({ message: 'Ocorreu um erro ao buscar os dados.' }));
  }, [notification, setSales, selectedDay]);

  useEffect(() => {
    loadSales();
  }, [loadSales]);

  const handleDownloadSheet = useCallback(async () => {
    setDownloadLoading(true);
    try {
      const { data } = await kexiOnApi.get('/sales', {
        params: {
          page: 0,
          all: 'true',
          ...(selectedDay && { date: selectedDay.format('YYYY-MM-DDT03:00:00Z') }),
        },
      });
      downloadSalesReport(data);
    } catch (err) {
      notification.error({ message: 'Não foi possível processar sua solicitação.' });
    }
    setDownloadLoading(false);
  }, [selectedDay]);

  const formattedSales = useMemo(() => sales?.map((sale) => ({
    code: sale.code,
    paid_at: sale.paid_at ? moment(sale.paid_at).format('DD/MM/YYYY') : null,
    value: `R$ ${HandleFormatMoney(sale.value)}`,
    status: sale.paid ? (
      <span style={{ color: '#75c877', fontWeight: 'bold' }}>Pago</span>
    ) : (
      <span style={{ color: '#da5f5f', fontWeight: 'bold' }}>Em aberto</span>
    ),
    customer_name: sale.customer && sale.customer.name,
    credit_due_at: sale.credit_due_at ? moment(sale.credit_due_at).format('DD/MM/YYYY') : null,
    customer_document: sale.customer && sale.customer.document,
    customer_phone: sale.customer && sale.customer.phone,
  })), [sales]);

  const handleCreateSale = useCallback(async () => {
    if (value <= 0 || !value) {
      setValueError('Por favor insira um valor maior que 0.');

      return;
    }

    try {
      const response = await kexiOnApi.post('sales', { value });

      setCode(response.data.code);
      setOpenCodeModal(true);
    } catch (e) {
      notification.error({ message: 'Ocorreu um erro.' });
    }
  }, [
    value,
    kexiOnApi,
    notification,
    setValueError,
    setCode,
    setOpenCodeModal,
  ]);

  return (
    <Container>
      <CodeModal
        visible={!!openCodeModal}
        hidden={() => {
          setOpenCodeModal(false);
          setValue(0);
          loadSales();
        }}
        title="Por favor reenvie os documentos."
        code={code}
        value={value}
      />
      <Content>
        <div style={{
          width: '100%', maxWidth: '536px', flexWrap: 'wrap', alignItems: 'center',
        }}
        >
          <Typography.Title level={3}>Digite o valor da venda para gerar um código.</Typography.Title>
        </div>
        <Input
          autoComplete="off"
          decimalSeparator=","
          thousandSeparator="."
          value={value}
          onChangeEvent={(event, maskedvalue, floatvalue) => {
            setValue(floatvalue);
          }}
        />

        <div style={{ marginTop: '2px', height: '10px' }}>
          {valueError.length > 0 && (
          <p style={{ color: 'red', fontSize: '12px' }}>{valueError}</p>
          )}
        </div>

        <ButtonContainer>
          <StyledButton
            onClick={handleCreateSale}
          >
            Continuar
          </StyledButton>
        </ButtonContainer>
        <div style={{
          display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 10,
        }}
        >
          <DatePicker locale={locale} format="DD/MM/YYYY" onChange={(date) => setSelectedDay(date)} />
          <Button onClick={downloadLoading ? () => {} : handleDownloadSheet}>
            {downloadLoading ? <Spin size={15} /> : 'Exportar Excel'}
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={formattedSales}
          scroll={{ y: 400, scrollToFirstRowOnChange: true }}
          style={{ marginTop: 10 }}
          pagination={false}
        />
        <Pagination
          current={currentPage + 1}
          onChange={(page) => loadSales(page)}
          total={totalItems}
          pageSize={15}
          style={{ alignSelf: 'flex-end', marginTop: '6px', marginBottom: '36px' }}
        />
      </Content>
    </Container>
  );
};
export default KexiFarna;
