import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { FiUser, FiLock } from 'react-icons/fi';

import { Alert, Button } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { login, isAuthenticated } from '~/services/auth';
import api from '~/services/api';

import Product from '~/services/product';

import {
  Row, Col, RawInput, PasswordInput, Label,
} from '~/styles/components';

import {
  Container, FormContainer, ImageBackground, Logo,
} from './styles';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  async function handleLogin(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { data: { token } } = await api.post('sessions', {
        email,
        password,
      });
      if (token) {
        login(token);
        setLoading(false);
        localStorage.setItem('Email', email);
        history.push('/dashboard');
      }
    } catch (err) {
      setLoading(false);
      setError('Não foi possível fazer o login. Verifique se o E-mail e/ou Senha estão corretos');
    }
  }

  if (isAuthenticated()) return <Redirect to="/dashboard" />;

  return (
    <Container>
      <FormContainer md={{ span: 12 }} lg={{ span: 12 }}>
        <div>
          <Logo>
            {/* <img src={Product.logoHome} alt="Logo" /> */}
          </Logo>
          <br />
          <div><strong>Bem Vindo à Loja Online</strong></div>
          <span>Feliz em vê-lo novamente!</span>


          <form onSubmit={handleLogin}>
            <Row>
              <Col md={{ span: 15 }} lg={{ span: 15 }} style={{ marginTop: 20 }}>
                {!!error && (
                  <Alert style={{ marginBottom: 20 }} type="error" message="Erro!" description={error} />
                )}
                <Row style={{ marginBottom: 20 }}>
                  <Label>E-mail</Label>
                  <Col>
                    <RawInput
                      prefix={<FiUser />}
                      name="email"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Label>Senha</Label>
                  <Col>
                    <PasswordInput
                      prefix={<FiLock />}
                      name="password"
                      placeholder="Senha"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 30 }}>
                  <Col>
                    <Button htmlType="submit" block type="primary" size="large">
                      {loading && <LoadingOutlined />}
                      <span style={{ paddingLeft: 10 }}>ENTRAR</span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>


        </div>
      </FormContainer>
      <ImageBackground md={{ span: 12 }} lg={{ span: 12 }}>
        <div />
      </ImageBackground>
    </Container>
  );
}
