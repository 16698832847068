/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import produce from 'immer';


import {
  Modal, Divider, Typography, List, Button, Checkbox,
} from 'antd';

import ListTrello from '../List';

import BoardContext from './context';
import { Container } from './styles';

import api from '~/services/api';


export default function Board({ data }) {
  const [lists, setLists] = useState(data);
  const [modalState, setModalState] = useState(false);
  const [modalData, setModalData] = useState({});
  const [result, setResult] = useState({});


  function move(fromList, toList, from, to) {
    setLists(produce(lists, (draft) => {

      try{
        const dragged = draft[fromList].cards[from];

        draft[fromList].cards.splice(from, 1);

        if(!draft[fromList].cards.length){
          draft[fromList].cards.push({
            id: -1,
            content: 'invisible',
            labels: [],
            data: { result: false },
          });
        }
        draft[toList].cards.splice(to, 0, dragged);

        
        if (toList === 3 || toList === 4 || toList === 5 ) {
          let status = 'sent_for_delivery';

          // if (toList === 2) {
          //   status = 'allocating';
          // }

          if (toList === 3) {
            status = 'sent_for_delivery';
          }

          if (toList === 4) {
            status = 'failure';
          }

          if (toList === 5) {
            status = 'delivered';
          }

          const c = lists[fromList].cards[from];
          const { id } = c.data;

          api.post(`notification/status_manual?id=${id}&status=${status}`).then((dx) => {
            console.log(dx);
          }).catch((err) => {
            console.log('e', err);
          });
        } 
      } catch(error){
        console.log(error)
      }
    }));
  }

  return (
    <BoardContext.Provider value={{ lists, move }}>
      <Container>
        {lists.map((list, index) => (
          <ListTrello
            onClicked={(dataParam) => {
              setModalState(true);
              setModalData(dataParam);
              setResult(dataParam.data.result);
            }}
            key={list.title}
            index={index}
            data={list}
          />
        ))}
      </Container>

      {modalData.data && modalData.data.id && result && result.id && (
      <Modal
        title={`Pedido: ${modalData.data.name} | ${modalData.data.phone.replace('+55', '')}`}
        style={{ borderRadius: 8 }}
        visible={modalState}
        centered
        onOk={() => setModalState(false)}
        onCancel={() => setModalState(false)}
      >
        {console.log(modalData, result)}
        <Divider orientation="left">Produtos</Divider>
        <List
          dataSource={result.products.items}
          renderItem={(item) => (
            <List.Item>
              {modalData && modalData.receipt ? (
                <Checkbox onChange={() => {
                  // console.log(`checked ${item.id}`);
                }}
                >
                  <Typography.Text>{item.quantity}x</Typography.Text> {item.name}
                </Checkbox>
              ) : (
                <span>
                  <Typography.Text>{item.quantity}x</Typography.Text> {item.name}
                </span>
              )}
            </List.Item>
          )}
        />

        <Divider orientation="left">Endereço</Divider>
        <p>{result.address.street}, {result.address.number} {result.address.complement}</p>
        <p>{result.address.neighborhood} {result.address.city} {result.address.country}</p>
        <p>{result.address.zip_code}</p>

        <Divider orientation="left">Método de pagamento</Divider>
        <p>{modalData.data.payment_type}</p>

        <Button
          type="primary"
          onClick={() => {
            window.open(`https://ecommerce.qesh.ai/files/${modalData.data.list}`);
          }}
        >Imprimir pedido
        </Button>
      </Modal>
      )}
    </BoardContext.Provider>
  );
}
