import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Menu, Dropdown, Badge,
} from 'antd';

import { FiChevronDown, FiBell } from 'react-icons/fi';
import Axios from 'axios';
import Product from '~/services/product';

import {
  Container, InfoRight, Notification,
} from './styles';

// import LogoQesh from '~/assets/logo_qesh.png';

export default function Header() {
  const { user } = useSelector((state) => state.user);
  const history = useHistory();
  const [clientSettings, setClientSettings] = useState(null);

  useEffect(() => {
    if (user && user.product_name) {
      Axios.get('https://bank.qesh.ai/client-settings', {
        headers: {
          name: user.product_name,
        },
      }).then((resp) => {
        setClientSettings(resp.data);
      }).catch(() => {
        setClientSettings({ default_logo: Product.logo });
      });
    } else {
      setClientSettings({ default_logo: Product.logo });
    }
  }, [user, Product]);

  const menu = (
    <Menu style={{ borderRadius: 4 }}>
      <div key="1" style={{ cursor: 'pointer', padding: 10 }} onClick={() => history.push('/settings')}>
        Minha loja
      </div>
      <div key="3" style={{ cursor: 'pointer', padding: 10 }} onClick={() => history.push('/mensalidades')}>
        Mensalidades
      </div>
      <div key="2" style={{ cursor: 'pointer', padding: 10 }} onClick={() => history.push('/logout')}>
        Sair
      </div>
    </Menu>
  );

  const notificationBox = (
    <div
      style={{
        background: '#FFFFFF', padding: '15px 20px', borderRadius: 4, minWidth: 390, minHeight: 120,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{
          fontWeight: 500,
          fontSize: '20px',
        }}
        >Notificações
        </div>
        <span>Marcar todas como lidas</span>
      </div>
      <div
        style={{
          paddingTop: 30,
          display: 'flex',
          fontSize: '16px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Sem notificações.
      </div>
    </div>
  );

  return (
    <Container>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 20 }}>
          <img src={clientSettings && clientSettings.default_logo ? clientSettings.default_logo : ''} alt="Logo" style={{ maxWidth: 200, maxHeight: 70 }} />
        </div>
      </span>
      <InfoRight>
        {/*
        <Dropdown overlay={notificationBox}>
          <Badge count={0}>
            <Notification>
              <FiBell size={20} color="#636466" />
            </Notification>
          </Badge>
        </Dropdown>
        */}
        <Dropdown overlay={menu}>
          <span style={{
            cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: 30,
          }}
          >
            {user.logo ? (
              <img style={{ borderRadius: 6, marginRight: 10 }} src={user.logo} alt="avatar" width="50" height="50" />
            ) : (
              <>
                <div style={{
                  display: 'flex',
                  borderRadius: 6,
                  width: 50,
                  height: 50,
                  background: '#FFFFFF',
                  padding: 3,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 10,
                  fontSize: 18,
                  color: '#636466',
                }}
                >
                  <strong>{user.name.substr(0, 1)}</strong>
                </div>
              </>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
              <strong>Minha Conta</strong>
              <span>{user.name}</span>
            </div>
            <FiChevronDown size={16} />
          </span>
        </Dropdown>
      </InfoRight>
    </Container>
  );
}
