/**
  Types
 */

export const Types = {
  GET_REQUEST_USER: 'user/GET_REQUEST_USER',
  GET_SUCCESS_USER: 'user/GET_SUCCESS_USER',
};

/**
  Reducers
 */
const INITIAL_STATE = {
  checkedUser: false,
  loading: false,
  user: {},
};

export default function use(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST_USER:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_SUCCESS_USER:
      return {
        ...state,
        loading: false,
        checkedUser: true,
        user: action.payload.data,
      };
    default:
      return state;
  }
}

/**
 Actions
 */

export const Creators = {
  getUser: () => ({
    type: Types.GET_REQUEST_USER,
  }),
  getSucessUser: (data) => ({
    type: Types.GET_SUCCESS_USER,
    payload: { data },
  }),
};
