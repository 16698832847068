import xl from 'excel4node';
import FileSaver from 'file-saver';
import moment from 'moment';

const options = {
  sheetView: {
    showGridLines: true,
  },
};

export async function create(
  sheetData,
) {
  const wb = new xl.Workbook();
  const ws = wb.addWorksheet('Vendas KéxiFarma', options);

  ws.cell(1, 1).string('Código');
  ws.cell(1, 2).string('Data da venda');
  ws.cell(1, 3).string('Valor');
  ws.cell(1, 4).string('Situação');
  ws.cell(1, 5).string('Nome do cliente');
  ws.cell(1, 6).string('Data do vencimento');
  ws.cell(1, 7).string('CPF do cliente');
  ws.cell(1, 8).string('Número do telefone');

  sheetData.forEach((item, index) => {
    ws.cell(index + 2, 1).string(item.code);
    ws.cell(index + 2, 2).string(item.paid_at ? moment(item.paid_at).format('DD/MM/YYYY') : '');
    ws.cell(index + 2, 3).number(+item.value || 0);
    ws.cell(index + 2, 4).string(item.paid ? 'Pago' : 'Em aberto');
    ws.cell(index + 2, 5).string(item.customer && item.customer.name ? item.customer.name : '');
    ws.cell(index + 2, 6).string(item.credit_due_at ? moment(item.credit_due_at).format('DD/MM/YYYY') : '');
    ws.cell(index + 2, 7).string(item.customer && item.customer.document ? item.customer.document : '');
    ws.cell(index + 2, 8).string(item.customer && item.customer.phone ? item.customer.phone : '');
  });

  const excelBuffer = await wb.writeToBuffer();
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `Vendas_${moment().format('YYYY-MM-DD')}.xlsx`);
}
