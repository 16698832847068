import { call, put } from 'redux-saga/effects';


import api from '~/services/api';

import { Creators as ClientAction } from '~/store/ducks/client';

export function* requestClient() {
  try {
    const { data } = yield call(api.get, 'admin/clients');


    yield put(ClientAction.getSuccessClient(data.clients));
  } catch (err) {
    //
  }
}
