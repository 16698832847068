import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Tooltip,
  Upload,
  Button,
  message,
  Steps,
  Divider,
  Result,
} from 'antd';
import { IoIosHelpCircle } from 'react-icons/io';
import InputMask from 'react-input-mask';
import { GrAdd, GrFormSubtract } from 'react-icons/gr';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { Container } from './styles';
import { Creators as ProductCreators } from '~/store/ducks/product';
import 'bootstrap/dist/css/bootstrap.css';
import { BIGDATA_API_KEY, getToken } from '~/services/auth';
import {
  HandleMoneyToFloat,
  HandleFormatMoney,
  typeNumberInt,
} from '~/services/utils';
import socket from '~/services/notification';
import {
  RowGutter,
  Col,
  RawInput,
  Label,
  SelectInput,
} from '~/styles/components';
import api from '~/services/api';

import { Creators as ActionUser } from '~/store/ducks/user';

const { Step } = Steps;
const { Dragger } = Upload;

export default function Main() {
  const dispatch = useDispatch();
  const io = socket();
  const [inputFields, setInputFields] = useState([
    {
      cpf: '',
      departament: '',
      product: '',
      name: '',
      price: '',
      quantity: '',
    },
  ]);
  const [nameClient, setName] = useState([]);
  const [foneClient, setFone] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState([]);
  const [step, setStep] = useState(0);
  const [product, setProduct] = useState({});
  const [error, setError] = useState({ field: '', message: '' });
  const { products, loadings } = useSelector((state) => state.product);
  const [departments, setDepartments] = useState([]);

  const [source, setSource] = useState([]);

  dispatch(ActionUser.getUser());

  api.interceptors.request.use(async (config) => {
    const configParam = config;
    const token = getToken();
    configParam.headers.Authorization = `Bearer ${token}`;
    return configParam;
  });

  useEffect(() => {
    api.get('products/user/departments').then(({ data }) => {
      const newData = data.map((d) => ({
        id: d.id,
        value: d.name,
        text: d.name,
      }));

      setDepartments(newData);
    });
  }, []);

  useEffect(() => {
    dispatch(ProductCreators.getRequestProduct());
    io.on('REMOVE_CART', () => {
      dispatch(ProductCreators.getRequestBackgroundProduct());
    });

    io.on('ADD_CART', () => {
      dispatch(ProductCreators.getRequestBackgroundProduct());
    });
  }, [dispatch, io]);

  function handleChange(value, index) {
    setProduct({
      ...product,
      department: value,
    });

    let source2 = products.map((product) => {
      if (product.department.name === value) {
        return (
          <SelectInput.Option key={product.identifier}>
            {product.name}
          </SelectInput.Option>
        );
      }
    });

    source2 = source2.filter((n) => n);
    source[index] = source2;
    inputFields[index].departament = value;
  }

  function handleChangeProduct(value, index) {
    setProduct({
      ...product,
      product: value,
    });

    inputFields[index].product = value;
  }

  async function searchName(cpf) {
    const { data: nome } = await Axios.get(
      `https://datalake.qesh.ai/api/v1/people?document=${cpf}`,
      {
        headers: {
          Authorization: BIGDATA_API_KEY,
        },
      },
    );
    return nome.Result[0].BasicData.Name;
  }

  async function searchClienByDocument(cpf) {
    const mail = localStorage.getItem('Email');
    const { data: userShop } = await api.get(`/users/email?email=${mail}`);
    let clientShop;

    try {
      const { data } = await Axios.get(
        `https://ecommerce.qesh.ai/clients/${cpf}`,
        {
          headers: {
            user: userShop.id,
          },
        },
      );

      clientShop = data;
    } catch (_e) {
      const { data } = await Axios.get(
        `https://cashback.qesh.ai/cashback/guarudigital/balance?document=${cpf}`,
        {
          headers: {
            admin: '6daaa86c2472418ce8b4a8e8790d8c1e',
            user: userShop.id,
          },
        },
      );

      clientShop = data;
    }

    return clientShop;
  }

  async function handleInputProduct(e, index) {
    const { name, value } = e.target;

    if (name === 'cpf') {
      inputFields[0].cpf = value;
      const cpf = value.replace(/[^0-9]/g, '');

      if (cpf.length == 11) {
        const nome = await searchName(cpf);
        try {
          const clientShop = await searchClienByDocument(cpf);

          setFone(clientShop.phone.replace(/^\+55/, ''));
        } catch (err) {
          console.log(err);
        }
        setName(nome);
      } else {
        setName('Digite o CPF');
      }
    } else if (name === 'foneClient') {
      setFone(value);
    } else {
      let flag = false;
      products.map((product) => {
        if (product.identifier === inputFields[index].product) {
          if (product.quantity >= Number(value)) {
            setError({ field: 'quantity'.concat(index), message: '' });
          } else {
            setError({
              field: 'quantity'.concat(index),
              message: 'A quantidade selecionada excede o limite do estoque.',
            });
            // message.error('A quantidade selecionada excede o limite do estoque.');
            flag = true;
          }
        }
      });
      if (flag) {
        return;
      }

      let priceFinal = 0;
      inputFields[index].quantity = value;

      for (let i = 0; i < inputFields.length; i += 1) {
        products.map((product) => {
          if (product.identifier === inputFields[i].product) {
            priceFinal
              += Number(product.price) * Number(inputFields[i].quantity);
          }
        });
      }

      setPrice({
        ...price,
        price: priceFinal,
      });
    }
  }

  const handleAddFields = (index) => {
    const values = [...inputFields];
    values.push({
      departament: '',
      product: '',
      name: '',
      price: '',
      quantity: '',
    });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);

    // Atualiza valor do preco total

    let priceFinal = 0;

    for (let i = 0; i < values.length; i += 1) {
      products.map((product) => {
        if (product.identifier === values[i].product) {
          priceFinal
            += (Number(product.price) + Number(product.cashback))
            * Number(values[i].quantity);
        }
      });
    }

    setPrice({
      ...price,
      price: priceFinal,
    });
  };

  async function nextStepProduct(e) {
    e.preventDefault();
    const requiredFields = ['cpf', 'department', 'product', 'quantity'];
    setError({ field: '', message: '' });

    for (let i = 0; i < requiredFields.length; i += 1) {
      const current = requiredFields[i];

      if (!product.department) {
        setError({ field: 'department', message: 'Selecione um departamento' });
        message.error(
          'Há erros no formulário, por favor corrija e tente novamente',
        );
        return;
      }

      if (!product.product) {
        setError({
          field: current,
          message: 'Campo de produto não pode ficar em branco',
        });
        message.error(
          'Há erros no formulário, por favor corrija e tente novamente',
        );
        return;
      }

      if (product.quantity < 0) {
        setError({
          field: 'quantity',
          message: 'A quantidade não pode ser menor que 0',
        });
        message.error(
          'Há erros no formulário, por favor corrija e tente novamente',
        );
        return;
      }
    }

    setLoading(true);
    try {
      let qtd;
      let currentProduct;
      let priceFinal = 0;
      let cashbackTotal = 0;
      for (let index = 0; index < inputFields.length; index += 1) {
        products.map((product) => {
          if (product.identifier === inputFields[index].product) {
            inputFields[index].name = product.name;
            inputFields[index].price = product.price + product.cashback;
            priceFinal
              += (Number(product.price) + Number(product.cashback))
              * Number(inputFields[index].quantity);
            cashbackTotal
              += Number(product.cashback) * Number(inputFields[index].quantity);
            currentProduct = product;
            qtd = product.quantity -= Number(inputFields[index].quantity);
            if (qtd < 0) {
              setError({
                field: 'quantity',
                message: 'A quantidade não pode ser menor que 0',
              });
              message.error(
                'Há erros no formulário, por favor corrija e tente novamente',
              );
            }
          }
        });

        if (qtd < 0) {
          return;
        }

        setPrice({
          price: priceFinal,
        });

        const { data: currentProductData } = await api.put(
          `/products/${currentProduct.id}`,
          {
            quantity: qtd,
          },
        );
      }

      const token = localStorage.getItem('@qeshecommerce-token');
      const mail = localStorage.getItem('Email');

      const { data: User } = await api.get(`/users/email?email=${mail}`);

      const cpf = inputFields[0].cpf.replace(/[^0-9]/g, '');

      if (cashbackTotal > 0) {
        const { data: externa } = await Axios.post(
          `https://cashback.qesh.ai/cashback/${User.product_name}/balance`,
          {
            document: cpf,
            name: nameClient,
            phone: foneClient,
            description: 'Venda externa',
            amount: cashbackTotal,
            shop_id: User.id,
            origin: 'EXTERNAL',
            order_total: priceFinal,
          },
          {
            headers: {
              admin: '6daaa86c2472418ce8b4a8e8790d8c1e',
            },
          },
        );
      }

      console.log('inseriu venda no concluido');

      // inserindo na tabela de extrato de vendas

      console.log(token);

      const { data: extrato } = await Axios.post(
        'https://ecommerce.qesh.ai/admin/clients/external-purchases',
        {
          document: cpf,
          description: 'Venda externa',
          total_value: priceFinal,
          cashback: cashbackTotal,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      console.log(extrato);

      setStep(step + 1); // ocultar imagem
      setLoading(false);
    } catch (err) {
      message.error('Não foi possível cadastrar a venda externa.');
      console.log(err);
      setLoading(false);
    }
  }

  const options = departments.map((d) => (
    <SelectInput.Option key={d.value}>{d.text}</SelectInput.Option>
  ));

  const props = {
    name: 'image',
    multiple: false,
    action: `https://ecommerce.qesh.ai/products/${product.id}/image`,
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} alterada com sucesso!`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} erro ao fazer upload.`);
      }
    },
  };

  return (
    <>
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Typography.Title level={3}>Nova venda externa</Typography.Title>
        </Col>

        <Col
          md={{ span: 24 }}
          lg={{ span: 24 }}
          style={{ background: '#FFFFFF', padding: 20, borderRadius: 4 }}
        >
          <Steps current={step}>
            <Step title="Dados da venda" description="" />
            {/* <Step title="Imagem" subTitle="" description="" /> */}
            <Step title="Concluído" description="" />
          </Steps>

          <Divider />

          {step === 0 && (
            <>
              <form onSubmit={nextStepProduct}>
                <RowGutter>
                  <Col lg={{ span: 7 }}>
                    <div>
                      <Label>
                        CPF:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="CPF do cliente"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <InputMask
                        placeholder="___.___.___-__"
                        mask="999.999.999-99"
                        name="cpf"
                        value={inputFields.cpf}
                        onChange={handleInputProduct}
                      />
                      {error && error.field === 'identifier' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 7 }}>
                    <div>
                      <Label>
                        Nome:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Nome do cliente"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <InputMask
                        placeholder="Nome Completo"
                        name="nameClient"
                        value={nameClient}
                        onChange={handleInputProduct}
                        disabled
                      />
                      {error && error.field === 'nameClient' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 6 }}>
                    <div>
                      <Label>
                        Telefone:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Telefone do cliente"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <InputMask
                        placeholder="(__) _____-____"
                        mask="(99) 99999-9999"
                        name="foneClient"
                        value={foneClient}
                        onChange={handleInputProduct}
                      />
                      {error && error.field === 'nameClient' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                </RowGutter>
                {inputFields.map((inputField, index) => (
                  <Fragment key={`${inputField}~${index}`}>
                    <RowGutter>
                      <Col lg={{ span: 7 }}>
                        <div>
                          <Label>
                            Departamento
                            <Tooltip
                              arrowPointAtCenter
                              placement="topLeft"
                              title="Este campo ajuda a direcionar o cliente,
                        agrupando produtos por departamentos"
                            >
                              <IoIosHelpCircle />
                            </Tooltip>
                          </Label>
                          <SelectInput
                            name="department"
                            value={inputField.departament}
                            placeholder="Selecione..."
                            defaultActiveFirstOption={false}
                            onChange={(e) => handleChange(e, index)}
                            showArrow
                            notFoundContent={null}
                            showSearch
                          >
                            {options}
                          </SelectInput>
                          {error && error.field === 'department' && (
                            <div style={{ color: 'red', textAlign: 'center' }}>
                              {error.message}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={{ span: 7 }}>
                        <div>
                          <Label>
                            Produto:
                            <Tooltip
                              arrowPointAtCenter
                              placement="topLeft"
                              title="Nome do produto. É o nome que vai aparecer para o cliente"
                            >
                              <IoIosHelpCircle />
                            </Tooltip>
                          </Label>
                          <SelectInput
                            name="product"
                            value={inputField.product}
                            placeholder="Selecione..."
                            defaultActiveFirstOption={false}
                            onChange={(e) => handleChangeProduct(e, index)}
                            showArrow
                            notFoundContent={null}
                            showSearch
                          >
                            {source[index]}
                          </SelectInput>

                          {error && error.field === 'product' && (
                            <div style={{ color: 'red', textAlign: 'center' }}>
                              {error.message}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={{ span: 7 }}>
                        <div>
                          <Label>
                            Quantidade
                            <Tooltip
                              arrowPointAtCenter
                              placement="topLeft"
                              title="Quantidade do produto selecionado"
                            >
                              <IoIosHelpCircle />
                            </Tooltip>
                          </Label>
                          <RawInput
                            min="0"
                            placeholder="130"
                            name={'quantity'.concat(index)}
                            type="number"
                            value={inputField.quantity}
                            onChange={(e) => handleInputProduct(e, index)}
                          />
                          {error
                            && error.field === 'quantity'.concat(index) && (
                              <div
                                style={{ color: 'red', textAlign: 'center' }}
                              >
                                {error.message}
                              </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={{ span: 3 }}>
                        <div
                          className="form-group col-sm-1"
                          style={{ marginTop: '20px' }}
                        >
                          <button
                            className="btn btn-link"
                            // style={{border: '1px solid red'}}
                            style={{
                              padding: '0 2px',
                              backgroundColor: '#D3D3D3',
                              marginBottom: '4px',
                            }}
                            type="button"
                            onClick={() => handleRemoveFields(index)}
                          >
                            <GrFormSubtract />
                          </button>
                          <button
                            className="btn btn-link"
                            style={{
                              padding: '0 2px',
                              backgroundColor: '#85C1E9',
                            }}
                            type="button"
                            onClick={() => handleAddFields(index)}
                          >
                            <GrAdd />
                          </button>
                        </div>
                      </Col>
                    </RowGutter>
                  </Fragment>
                ))}
                <Divider />
                <RowGutter>
                  <Col lg={{ span: 17 }} />
                  <Col lg={{ span: 4 }}>
                    <div>
                      <Label>
                        Valor total:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Valor total da venda avulsa"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <RawInput
                        placeholder="Total"
                        name="value"
                        value={`R$ ${HandleFormatMoney(price.price)}`}
                        disabled
                      />
                      {error && error.field === 'value' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                </RowGutter>
                <RowGutter>
                  <Col lg={{ span: 24 }}>
                    <div style={{ marginTop: 30 }}>
                      <Button
                        htmlType="submit"
                        type="primary"
                        block
                        size="large"
                        onClick={nextStepProduct}
                      >
                        {loading && <LoadingOutlined />}
                        {/* Próximo passo */}
                        Finalizar
                      </Button>
                    </div>
                  </Col>
                </RowGutter>
              </form>
            </>
          )}
          {step === 1 && (
            <>
              <RowGutter>
                <Col lg={{ span: 8 }} />
                <Col lg={{ span: 16 }}>
                  <Label>
                    <h3 style={{ color: '#4F0112' }}>
                      Preço total da venda R${HandleFormatMoney(price.price)}
                    </h3>
                  </Label>
                </Col>
              </RowGutter>
              <Result
                status="success"
                title="Venda externa cadastrado com sucesso!"
                extra={[,
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      setProduct({});
                      const values = [...inputFields];
                      values.splice(0, inputFields.length);
                      values.push({
                        departament: '',
                        product: '',
                        quantity: '',
                      });
                      setInputFields(values);
                      setPrice({ price: 0 });
                      setError({ field: '', message: '' });
                      setStep(0);
                    }}
                  >
                    Realizar nova venda
                  </Button>,
                  <Button key="buy" onClick={() => history.push('/estoque')}>
                    Ver estoque
                  </Button>,
                ]}
              />
            </>
          )}
        </Col>
      </Container>
    </>
  );
}
