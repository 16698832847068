export function HandleFormatMoney(amount, decimalCount = 2, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    // eslint-disable-next-line radix
    const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign
      + (j ? i.substr(0, j) + thousands : '')
      + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
      + (decimalCount
        ? decimal
          + Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}
export function HandleMoneyToFloat(amount) {
  try {
    const match = amount.match(/\d+/g);
    if (match != null) {
      let a = match.join();
      a = a.replace(/,/g, '');
      const dec = a.slice(a.length - 2);
      a = a.slice(0, a.length - 2);

      return `${a}.${dec}`;
    }
    return 0;
  } catch (e) {}
}

export function documentMask(val) {
  if (val && val.length) {
    const docVal = val.replace(/[/,.,-]/g, '');
    // MASK CPF / CNPJ
    if (docVal.length <= 14) {
      if (docVal.length > 12) {
        // cnpj  `##.###.###/####-##`
        const cnpj = /^([\d]{2})\.*([\d]{3})\.*([\d]{3})\/*([\d]{4})\.*([\d]{2})/; // Pode usar ? no lugar do *
        return docVal.replace(cnpj, '$1.$2.$3/$4-$5');
      }
      // cpf `###.###.###-##`
      const cpf = /([\d]{3})\.*([\d]{3})\.*([\d]{3})-*([\d]{2})/; // Pode usar ? no lugar do *
      return docVal.replace(cpf, '$1.$2.$3-$4');
    }
  }
  return val;
}

export const prepareName = (string) => {
  if (string && string.length) {
    const separeted = string.split(' ');
    const completedString = separeted.map((str) => (str.length <= 2
      ? str.toLowerCase()
      : str.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())));
    return completedString.join(' ');
  }
  return string;
};

export const typeNumberInt = (nr) => {
  if (typeof nr === 'string' && nr.match(/(\d+[,.]\d+)/)) return false;
  else if (typeof nr === 'string' && nr.match(/(\d+)/)) return true;
  else if (typeof nr === 'number') return nr % 1 === 0 ? true : false;
  else return false;
}
