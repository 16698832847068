import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Typography, Button, message, Divider, TimePicker, Upload,
} from 'antd';


import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getToken } from '~/services/auth';
import { Container } from './styles';


import {
  Row, RowGutter, Col, Label,
} from '~/styles/components';

import api from '~/services/api';


export default function Main() {
  const [loading, setLoading] = useState(false);
  const [userState, setUserState] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [link, setLink] = useState([]);

  const days = [
    { id: 1, name: 'Segunda-Feira' },
    { id: 2, name: 'Terça-Feira' },
    { id: 3, name: 'Quarta-Feira' },
    { id: 4, name: 'Quinta-Feira' },
    { id: 5, name: 'Sexta-Feira' },
    { id: 6, name: 'Sábado' },
    { id: 7, name: 'Domingo' },
  ];

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    setUserState(user);
    setImageUrl(user.logo);
    setLink(`https://vendas.qesh.ai/${user.id}`);
  }, [user]);

  async function handleEdit(e) {
    e.preventDefault();
    try {
      await api.put('users', { user: userState });
      message.success('Todos os dados foram atualizados');
    } catch (err) {
      message.error('Não foi possível atualizar os dados');
    }
  }

  // function handleChangeAvatar(e) {
  //   const valueCache = e.target.value;
  //   setUserState((oldUser) => ({
  //     ...oldUser,
  //     avatar: valueCache,
  //   }));
  // }

  // function handleChangeAvatarClose(e) {
  //   const valueCache = e.target.value;
  //   setUserState((oldUser) => ({
  //     ...oldUser,
  //     avatar_close: valueCache,
  //   }));
  // }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('O arquivo deve ser do formato JPG/PNG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('A imagem ultrapassa 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  function handleChange(info) {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      message.success('Logo cadastrada com sucesso');
      getBase64(info.file.originFileObj, (imageUrlParam) => {
        setLoading(false);
        setImageUrl(imageUrlParam);
      });
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <>
      {userState && (
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Typography.Title level={3}>Configurações</Typography.Title>
        </Col>

        <Col md={{ span: 24 }} lg={{ span: 24 }} style={{ background: '#FFFFFF', padding: 20, borderRadius: 4 }}>
          <Col md={{ span: 24 }} lg={{ span: 24 }}>
            <Typography.Title level={4}>Logo</Typography.Title>
          </Col>

          <form onSubmit={handleEdit}>
            <RowGutter>
              <Col lg={{ span: 24 }}>
                <div>
                  <Upload
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={`https://ecommerce.qesh.ai/users/${userState.id}/avatar?type=avatar`}
                    headers={{
                      authorization: `Bearer ${getToken()}`,
                    }}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    {imageUrl
                      ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                      : uploadButton}
                  </Upload>
                </div>
              </Col>
            </RowGutter>
            <Divider />
            <RowGutter>
              <Col md={{ span: 12 }} lg={{ span: 12 }}>
              <a target='_blank' href={link}>
                Para abrir sua Vitrine Online, clique aqui.
              </a>
              </Col>
            </RowGutter>
            <RowGutter>
              <Col md={{ span: 12 }} lg={{ span: 12 }}>
                <Col md={{ span: 24 }} lg={{ span: 24 }}>
                  <Typography.Title level={4}>Horários</Typography.Title>
                </Col>

                {days.map((day) => (
                  <Col md={{ span: 24 }} lg={{ span: 24 }} key={day.id}>
                    <Label>{day.name}</Label>
                    <Row style={{ alignItems: 'center' }}>
                      <Col md={{ span: 4 }}>
                        <TimePicker
                          defaultValue={userState.delivery[day.id].start
                            ? moment(userState.delivery[day.id].start, 'HH:mm') : null}
                          format="HH:mm"
                          onChange={(e) => {
                            setUserState((oldUser) => ({
                              ...oldUser,
                              delivery: {
                                ...oldUser.delivery,
                                [day.id]: {
                                  start: e ? e.format('HH:mm') : moment('00:01', 'HH:mm'),
                                  end: oldUser.delivery[day.id].end,
                                },
                              },
                            }));
                          }}
                        />
                      </Col>
                      <Col md={{ span: 1 }} style={{ textAlign: 'center' }}>
                        ás
                      </Col>
                      <Col md={{ span: 4 }}>
                        <TimePicker
                          defaultValue={userState.delivery[day.id].end
                            ? moment(userState.delivery[day.id].end, 'HH:mm') : null}
                          format="HH:mm"
                          onChange={(e) => {
                            setUserState((oldUser) => ({
                              ...oldUser,
                              delivery: {
                                ...oldUser.delivery,
                                [day.id]: {
                                  start: oldUser.delivery[day.id].start,
                                  end: e ? e.format('HH:mm') : moment('23:59', 'HH:mm'),
                                },
                              },
                            }));
                          }}
                        />
                      </Col>
                    </Row>
                    <Divider />
                  </Col>
                ))}
              </Col>

              {/* <Col md={{ span: 12 }} lg={{ span: 12 }}>
                <Col md={{ span: 24 }} lg={{ span: 24 }}>
                  <Typography.Title level={4}>Pagamentos aceitos</Typography.Title>
                </Col>
              </Col> */}
            </RowGutter>

            <RowGutter>
              <Col lg={{ span: 4 }}>
                <div style={{ marginTop: 30 }}>
                  <Button htmlType="submit" type="primary" block size="large" onClick={() => {}}>
                    {loading && <LoadingOutlined />}
                    Atualizar
                  </Button>
                </div>
              </Col>
            </RowGutter>
          </form>
        </Col>
      </Container>
      )}
    </>
  );
}
