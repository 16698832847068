/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CopyToClipboard } from 'react-copy-to-clipboard';


import {
  Skeleton, Table, Popconfirm, Upload, Divider, Button, Typography, Drawer, Tooltip, message,
} from 'antd';


import { IoIosHelpCircle } from 'react-icons/io';
import { BiEdit, BiTrash, BiXCircle } from 'react-icons/bi';
import { LoadingOutlined, CopyOutlined, InboxOutlined } from '@ant-design/icons';
// import { InboxOutlined } from '@ant-design/icons';

import {
  RowGutter, Col, RawInput, Label, SelectInput,
} from '~/styles/components';

import api from '~/services/api';
import { getToken } from '~/services/auth';

import { Creators as ProductCreators } from '~/store/ducks/product';

import socket from '~/services/notification';

import { HandleFormatMoney, HandleMoneyToFloat, typeNumberInt } from '~/services/utils';

const { Dragger } = Upload;

export default function Main() {
  const dispatch = useDispatch();
  const io = socket();
  const { products, loading } = useSelector((state) => state.product);
  const [dataSource, setDataSouce] = useState([]);

  const [productEdit, setProductEdit] = useState({});
  const [viewEdit, setViewEdit] = useState(false);
  const [error, setError] = useState({ field: '', message: '' });
  const [loadingSave, setLoadingSave] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [departmentsFilter, setDepartmentsFilter] = useState([]);

  useEffect(() => {
    api.get('products/user/departments').then(({ data }) => {
      const newData = data.map((d) => ({
        id: d.id,
        value: d.name,
        text: d.name,
      }));

      const filternewData = data.map((d) => ({
        value: d.id,
        text: d.name,
      }));

      setDepartments(newData);
      setDepartmentsFilter(filternewData);
    });
  }, []);

  function handleChange(value) {
    const depart = departments.find((dpt) => dpt.value === value);

    setProductEdit({
      ...productEdit,
      department: depart.id,
    });
  }

  function handleInputProduct(e) {
    const { name, value } = e.target;

    setProductEdit({
      ...productEdit,
      [name]: value,
    });
  }

  async function handleSaveProduct() {
    const requiredFields = ['identifier', 'name', 'quantity', 'price', 'department'];
    setError({ field: '', message: '' });

    for (let i = 0; i < requiredFields.length; i += 1) {
      const current = requiredFields[i];

      if (!productEdit[current]) {
        setError({ field: current, message: 'Este campo não pode ficar em branco' });
        message.error('Há erros no formulário, por favor corrija e tente novamente');
        return;
      }

      if (!productEdit.department) {
        setError({ field: 'department', message: 'Selecione um departamento' });
        message.error('Há erros no formulário, por favor corrija e tente novamente');
        return;
      }

      if (productEdit.quantity < 0) {
        setError({ field: 'quantity', message: 'A quantidade não pode ser menor que 0' });
        message.error('Há erros no formulário, por favor corrija e tente novamente');
        return;
      }

      if (!typeNumberInt(productEdit.quantity)) {
        setError({ field: 'quantity', message: 'A quantidade deve ser um número inteiro' });
        message.error('Há erros no formulário, por favor corrija e tente novamente');
        return;
      }

      if (productEdit.price < 0) {
        setError({ field: 'price', message: 'O valor do produto não pode ser menor que 0' });
        message.error('Há erros no formulário, por favor corrija e tente novamente');
        return;
      }
    }

    if (!typeNumberInt(productEdit.department)) {
      const depart = departments.find((dpt) => dpt.value === productEdit.department);
      productEdit.department = depart.id;
      setProductEdit({
        ...productEdit,
      });
    }

    if (typeof productEdit.price === 'string') {
      if (productEdit.price.slice(0, 3) === 'R$ ') {
        const priceSlice = productEdit.price.slice(3);
        const priceReplace = priceSlice.replace(',', '.');
        productEdit.price = parseFloat(priceReplace);
        setProductEdit({
          ...productEdit,
        });
      }
    }

    if (typeof productEdit.cashback === 'string') {
      if (productEdit.cashback.slice(0, 3) === 'R$ ') {
        const cashbackSlice = productEdit.cashback.slice(3);
        const cashbackReplace = cashbackSlice.replace(',', '.');
        productEdit.cashback = parseFloat(cashbackReplace);
        setProductEdit({
          ...productEdit,
        });
      }
    }

    setLoadingSave(true);
    try {
      await api.put(`/products/${productEdit.id}`, {
        ...productEdit,
        price: HandleMoneyToFloat(HandleFormatMoney(productEdit.price)),
        cashback: HandleMoneyToFloat(HandleFormatMoney(productEdit.cashback)),
      });
      setLoadingSave(false);
      message.success('Produto salvo com sucesso!');
      setViewEdit(false);
      dispatch(ProductCreators.getRequestBackgroundProduct());
      setProductEdit({});
    } catch (err) {
      setLoadingSave(false);
    }
  }

  useEffect(() => {
    dispatch(ProductCreators.getRequestProduct());
    io.on('REMOVE_CART', () => {
      dispatch(ProductCreators.getRequestBackgroundProduct());
    });

    io.on('ADD_CART', () => {
      dispatch(ProductCreators.getRequestBackgroundProduct());
    });
  }, [dispatch, io]);

  useEffect(() => {
    if (products.length) {
      const source = products.map((product, index) => ({
        key: index,
        id: product.id,
        identifier: product.identifier,
        name: product.name,
        quantity: product.quantity,
        price_value: product.price,
        price: `R$ ${HandleFormatMoney(product.price - product.cashback)}`,
        cashback: `R$ ${HandleFormatMoney(product.cashback)}`,
        ammount: `R$ ${HandleFormatMoney(product.price)}`,
        department: product.department ? product.department.name : '',
        description: product.description,
        image: product.image,
        department_id: product.department_id,
        link: `https://pay.qesh.ai/product/${product.id}`,
      }));

      setDataSouce(source);
    }
  }, [products]);

  function onClicked(product) {
    const { id } = product;
    dispatch(ProductCreators.requestDelete(id));
  }

  function onEdit(product) {
    setProductEdit(product);
    setViewEdit(true);
  }

  const columns = [

    {
      title: () => (
        <div style={{ width: '75px', fontWeight: 'bold' }}>
          Imagem
        </div>
      ),
      dataIndex: 'image',
      width: '80px',
      key: 'image',
      render: (text, record) => (
        <span style={{ padding: '10px' }}>
          {record.image ? (
            <img src={`https://ecommerce.qesh.ai/files/${record.image}`} alt="img" width="30" />
          ) : (
            <BiXCircle style={{ fontSize: '25px', align: 'center' }} />
          )}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ width: '75px', fontWeight: 'bold' }}>
          Nome
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '16%',
    },
    {
      title: () => (
        <div style={{ width: '80px', fontWeight: 'bold' }}>
          Quantidade
        </div>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '135px',
      sorter: (a, b) => a.quantity - b.quantity,
      render: (text) => (
        <div style={{
          textAlign: 'center',
          color: text < 50 ? '#ff4d4f' : 'inherit',
          fontWeight: text < 50 ? 'bold' : 'normal',
        }}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ width: '75px', fontWeight: 'bold' }}>
          Preço
        </div>
      ),
      dataIndex: 'price',
      key: 'price',
      width: '100px',
      sorter: (a, b) => a.price - b.price,
      render: (text) => (
        <div style={{ textAlign: 'center' }}>
          {text}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ width: '75px', fontWeight: 'bold' }}>
          Cash back
        </div>
      ),
      dataIndex: 'cashback',
      key: 'cashback',
      width: '120px',
      sorter: (a, b) => a.cashback - b.cashback,
      render: (text) => (
        <div style={{
          textAlign: 'center',
          color: text === 'R$ 0,00' ? 'inherit' : '#008000',
          fontWeight: text === 'R$ 0,00' ? 'inherit' : 'bold',
        }}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ width: '75px', fontWeight: 'bold' }}>
          Preço final
        </div>
      ),
      dataIndex: 'ammount',
      key: 'ammount',
      width: '100px',

    },
    {
      title: () => (
        <div style={{ width: '150px', fontWeight: 'bold' }}>
          Departamento
        </div>
      ),
      dataIndex: 'department',
      width: '150px',
      key: 'department',
    },
    {
      title: () => (
        <div style={{ width: '120px', fontWeight: 'bold' }}>
          Ações
        </div>
      ),
      dataIndex: 'delete',
      key: 'delete',
      width: '120px',
      render: (text, record) => (
        <span>
          <Popconfirm
            title="Tem certeza que deseja deletar este produto?"
            cancelText="Cancelar"
            okText="Ok"
            onConfirm={() => onClicked(record)}
          >
            <Button style={{ backgroundColor: '#D3D3D3', cursor: 'pointer', width: '90' }}>
              <BiTrash style={{ fontSize: '18px' }} />
            </Button>
          </Popconfirm>

          <Button onClick={() => onEdit(record)} style={{ backgroundColor: '#85C1E9', cursor: 'pointer' }}>
            <div style={{ cursor: 'pointer', textAlign: 'center' }}>
              <BiEdit style={{ fontSize: '18px' }} />
            </div>
          </Button>
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ width: '80px', fontWeight: 'bold' }}>
          Copiar link
        </div>
      ),
      dataIndex: 'link',
      key: 'link',
      width: '100px',
      render: (text) => (
        <CopyToClipboard
          text={text}
          onCopy={() => message.success('Link copiado com sucesso')}
        >
          <div style={{ cursor: 'pointer', textAlign: 'center' }}>
            <CopyOutlined style={{ fontSize: '18px', color: 'inherit' }} />
          </div>
        </CopyToClipboard>
      ),
      filters: departmentsFilter,
      onFilter: (value, record) => record.department_id === value,
    },
  ];

  const options = departments.map((d) => <SelectInput.Option key={d.value}>{d.text}</SelectInput.Option>);

  const props = {
    name: 'image',
    multiple: false,
    action: `https://ecommerce.qesh.ai/products/${productEdit.id}/image`,
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} alterada com sucesso!`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} erro ao fazer upload.`);
      }
    },
  };


  return (
    <>
      <Col md={{ span: 24 }} lg={{ span: 24 }}>
        <Typography.Title level={3}>Estoque</Typography.Title>
      </Col>
      <div>
        <Skeleton active paragraph={{ rows: 6 }} loading={loading}>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{ position: ['topRight', 'bottomRight'] }}
            scroll={{ y: 800, scrollToFirstRowOnChange: true }}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                >
                  {record.description}
                </div>
              ),
              rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
          />
        </Skeleton>
      </div>

      <Drawer
        title={productEdit.name}
        placement="right"
        width="60%"
        closable
        onClose={() => setViewEdit(false)}
        visible={viewEdit}
      >
        <RowGutter>
          <Col lg={{ span: 8 }}>
            <div>
              <Label>Identificador
                <Tooltip
                  arrowPointAtCenter
                  placement="topLeft"
                  title="Identificador unico para controle interno"
                >
                  <IoIosHelpCircle />
                </Tooltip>
              </Label>
              <RawInput
                placeholder="#401020"
                name="identifier"
                value={productEdit.identifier}
                onChange={handleInputProduct}
              />
              {error && error.field === 'identifier' && (
              <div style={{ color: 'red', textAlign: 'center' }}>
                {error.message}
              </div>
              )}
            </div>
          </Col>
          <Col lg={{ span: 8 }}>
            <div>
              <Label>Nome
                <Tooltip
                  arrowPointAtCenter
                  placement="topLeft"
                  title="Nome do produto. É o nome que vai aparecer para o cliente"
                >
                  <IoIosHelpCircle />
                </Tooltip>
              </Label>
              <RawInput
                placeholder="Arroz do tipo 1"
                name="name"
                value={productEdit.name}
                onChange={handleInputProduct}
              />
              {error && error.field === 'name' && (
              <div style={{ color: 'red', textAlign: 'center' }}>
                {error.message}
              </div>
              )}
            </div>
          </Col>
          <Col lg={{ span: 8 }}>
            <div>
              <Label>Departamento
                <Tooltip
                  arrowPointAtCenter
                  placement="topLeft"
                  title="Este campo ajuda a direcionar o cliente,
                    agrupando produtos por departamentos"
                >
                  <IoIosHelpCircle />
                </Tooltip>
              </Label>
              {productEdit.department_id && (
              <SelectInput
                name="department"
                defaultValue={departments.find((d) => d.id === productEdit.department_id).value}
                placeholder={departments.find((d) => d.id === productEdit.department_id).value || 'Selecione...'}
                defaultActiveFirstOption={false}
                onChange={handleChange}
                showArrow
                notFoundContent={null}
                showSearch
              >
                {options}
              </SelectInput>
              )}
            </div>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col lg={{ span: 8 }}>
            <div>
              <Label>Quantidade em estoque
                <Tooltip arrowPointAtCenter placement="topLeft" title="Quantidade disponível em estoque">
                  <IoIosHelpCircle />
                </Tooltip>
              </Label>
              <RawInput
                min="0"
                placeholder={productEdit.quantity}
                type="number"
                name="quantity"
                value={productEdit.quantity}
                onChange={handleInputProduct}
              />
              {error && error.field === 'quantity' && (
              <div style={{ color: 'red', textAlign: 'center' }}>
                {error.message}
              </div>
              )}
            </div>
          </Col>
          <Col lg={{ span: 8 }}>
            <div>
              <Label>Preço unitário
                <Tooltip arrowPointAtCenter placement="topLeft" title="Preço unitário do produto">
                  <IoIosHelpCircle />
                </Tooltip>
              </Label>
              <RawInput
                min="0"
                type="Number"
                placeholder={productEdit.price}
                name="price"
                value={productEdit.price}
                onChange={handleInputProduct}
              />
              {error && error.field === 'price' && (
              <div style={{ color: 'red', textAlign: 'center' }}>
                {error.message}
              </div>
              )}
            </div>
          </Col>
          <Col lg={{ span: 8 }}>
            <div>
              <Label>Cash back
                <Tooltip arrowPointAtCenter placement="topLeft" title="Cash back unitário do produto">
                  <IoIosHelpCircle />
                </Tooltip>
              </Label>
              <RawInput
                min="0"
                type="Number"
                placeholder={productEdit.cashback}
                name="cashback"
                value={productEdit.cashback}
                onChange={handleInputProduct}
              />
              {error && error.field === 'cashback' && (
              <div style={{ color: 'red', textAlign: 'center' }}>
                {error.message}
              </div>
              )}
            </div>
          </Col>
        </RowGutter>
        <RowGutter>
          <Col lg={{ span: 24 }}>
            <div>
              <Label>Descrição
                <Tooltip arrowPointAtCenter placement="topLeft" title="Descrição do produto">
                  <IoIosHelpCircle />
                </Tooltip>
              </Label>
              <RawInput
                placeholder=""
                name="description"
                value={productEdit.description}
                onChange={handleInputProduct}
              />
              {error && error.field === 'description' && (
              <div style={{ color: 'red', textAlign: 'center' }}>
                {error.message}
              </div>
              )}
            </div>
          </Col>
          <Col lg={{ span: 24 }}>
            <div style={{ marginTop: 30 }}>
              <Button htmlType="submit" type="primary" block size="large" onClick={handleSaveProduct}>
                {loadingSave && <LoadingOutlined />}
                Salvar
              </Button>
            </div>
          </Col>
        </RowGutter>

        <Divider />

        <RowGutter>
          <Col style={{ paddingBottom: 30 }}>
            <Col style={{ paddingBottom: 30 }}>
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Clique ou arraste a imagem para atualizar a foto do produto.</p>
                <p className="ant-upload-hint" />
              </Dragger>
            </Col>

          </Col>
        </RowGutter>

      </Drawer>
    </>
  );
}
