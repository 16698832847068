import React, {
  useEffect, useState, useRef, Component,
} from 'react';


// import { useSelector, useDispatch } from 'react-redux';
// import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useHistory } from 'react-router-dom';
import {
  Skeleton, Table, Popconfirm, Upload, Divider, Button, Steps, Result, Typography, Drawer, Tooltip, message,
} from 'antd';

import { IoIosHelpCircle } from 'react-icons/io';

import QRCode from 'react-qr-code';

import QRCodee from 'qrcode';


import { InboxOutlined, LoadingOutlined, CopyOutlined } from '@ant-design/icons';
// import { InboxOutlined } from '@ant-design/icons';

import InputMask from 'react-input-mask';

import Axios from 'axios';
import {
  RowGutter, Col, RawInput, Label, SelectInput,
} from '~/styles/components';

import api from '~/services/api';
import { BIGDATA_API_KEY, getToken } from '~/services/auth';


import socket from '~/services/notification';

import { HandleFormatMoney, HandleMoneyToFloat, typeNumberInt } from '~/services/utils';
import { Container } from './styles';


const { Step } = Steps;
const { Dragger } = Upload;
// const { Dragger } = Upload;


export default function Main() {
  const [cpf, setCpf] = useState([]);
  const [value, setValue] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [saldo, setSaldo] = useState([]);
  const [client, setClient] = useState([]);
  const [showInfo, setShow] = useState(false);
  const [step, setStep] = useState(0);
  const [qrValue, setQrValue] = useState({});
  const [viewEdit, setViewEdit] = useState(false);
  const [error, setError] = useState({ field: '', message: '' });
  const [options, setOptions] = useState([{ url: '', dest: '' }]);
  const [base64, setBase64] = useState([{ base64: '' }]);


  async function nextStepProduct(e) {
    e.preventDefault();

    setError({ field: '', message: '' });

    if (!cpf || cpf.replace(/[^0-9]/g, '').length != 11) {
      setError({ field: 'cpf', message: 'CPF incorreto' });
      message.error('Há erros no CPF informado, por favor corrija e tente novamente');
      return;
    }

    setLoading(true);


    try {
      const User = await pegaUser();

      const { data: saldo } = await Axios.get(`https://cashback.qesh.ai/cashback/${User.product_name}/balance?document=${cpf}`, {
        headers: {
          admin: '6daaa86c2472418ce8b4a8e8790d8c1e',
        },
      });

      const { data: nome } = await Axios.get(`https://datalake.qesh.ai/api/v1/people?document=${cpf}`, {
        headers: {
          Authorization: BIGDATA_API_KEY,
        },
      });

      // recebe o nome do cliente
      setClient(nome.Result[0].BasicData.Name);

      // aqui deve fazer o set do saldo do usuário pesquisado
      setSaldo(HandleFormatMoney(Number(saldo.balance)));

      setStep(step + 1);

      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error('Não foi possível consultar o cash back');
      setLoading(false);
    }
  }

  function onReturn() {
    setStep(step - 1); // ocultar imagem
    setViewEdit(false);
    setShow(false);
    setCpf('');
    setValue('');
  }

  function setViewClose() {
    setValue('');

    setViewEdit(false);
    setShow(false);
  }

  function onChange(e) {
    const { name, value } = e.target;
    setCpf(value.replace(/[^0-9]/g, ''));
  }

  function onEditValue(e) {
    const { name, value } = e.target;
    setValue(value);
  }

  async function pegaUser() {
    const mail = localStorage.getItem('Email');
    const { data: User } = await api.get(`/users/email?email=${mail}`);

    return User;
  }

  async function gerarqr() {
    setShow(!showInfo);

    const User = await pegaUser();

    const valueQr = JSON.stringify({
      type: 'withValue',
      shopId: User.id,
      shopName: User.name,
      shopAccount: User.account_id,
      description: `Compra realizada em ${User.name}`,
      value,
      document: cpf,
      flag: 'eCommerceQESH',
    });

    setQrValue(valueQr);

    QRCodee.toDataURL(valueQr)
      .then((url) => {
        console.log(url);
        setBase64({
          base64: url,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <QRCode id="qr-code-img" ref={ref} value={qrValue} />
  ));

  const componentRef = useRef();
  return (
    <>
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Typography.Title level={2}>Consultar cashback</Typography.Title>
        </Col>

        <Col md={{ span: 24 }} lg={{ span: 24 }} style={{ background: '#FFFFFF', padding: 20, borderRadius: 4 }}>
          <Steps current={step}>
            <Step title="Consultar" description="" />
            <Step title="Saldo" subTitle="" description="" />
          </Steps>

          <Divider />

          {step === 0 && (
            <>
              <form onSubmit={nextStepProduct}>

                <RowGutter>
                  <Col lg={{ span: 8 }}>
                    <div>
                      <Label>CPF:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="CPF do cliente"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <InputMask
                        placeholder="___.___.___-__"
                        mask="999.999.999-99"
                        name="cpf"
                        value={cpf}
                        onChange={(e) => onChange(e)}
                      />
                      {error && error.field === 'cpf' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                </RowGutter>
                <RowGutter>
                  <Col lg={{ span: 24 }}>
                    <div style={{ marginTop: 30 }}>
                      <Button htmlType="submit" type="primary" block size="large" onClick={nextStepProduct}>
                        {loading && <LoadingOutlined />}
                        {/* Próximo passo */}
                        Consultar
                      </Button>
                    </div>
                  </Col>
                </RowGutter>
              </form>
            </>
          )}

          {step === 1 && (
          <>
            <form>
              <RowGutter>
                <Col style={{ paddingBottom: 30, fontWeight: '600' }}>
                  <h3>
                    Cliente: {client}
                  </h3>

                  <h3>
                    Saldo de: R$ {saldo}
                  </h3>

                </Col>

                <Col md={{ span: 6 }} style={{ marginTop: 30 }}>
                  <Button block type="primary" size="large" onClick={() => onReturn()}>
                    Voltar
                  </Button>
                </Col>
                <Col md={{ span: 18 }} style={{ marginTop: 30 }}>
                  <Button block type="primary" size="large" onClick={() => setViewEdit(true)}>
                    Caso queira comprar com cashback clique aqui para gerar um QR code
                  </Button>
                  <Drawer
                    title="Valor"
                    placement="right"
                    width="35%"
                    closable
                    onClose={setViewClose}
                    visible={viewEdit}
                  >
                    <RowGutter>
                      <Col lg={{ span: 6 }} />
                      <Col lg={{ span: 8 }}>
                        <div>
                          <Label>Valor
                            <Tooltip arrowPointAtCenter placement="topLeft" title="Valor total do QR code">
                              <IoIosHelpCircle />
                            </Tooltip>
                          </Label>
                          <RawInput
                            min="0"
                            type="Number"
                            placeholder="R$ 0.00"
                            name="value"
                            value={value}
                            onChange={(e) => onEditValue(e)}
                          />
                          {error && error.field === 'value' && (
                          <div style={{ color: 'red', textAlign: 'center' }}>
                            {error.message}
                          </div>
                          )}
                        </div>
                      </Col>
                    </RowGutter>
                    <RowGutter>
                      <Col lg={{ span: 3 }} />
                      <Col md={{ span: 8 }} style={{ marginTop: 30 }}>
                        <Button block type="primary" size="large" onClick={() => onReturn()}>
                          Voltar
                        </Button>
                      </Col>
                      <Col lg={{ span: 8 }} style={{ marginTop: 30 }}>
                        <Button block type="primary" size="large" onClick={() => gerarqr()}>
                          Gerar
                        </Button>
                      </Col>
                    </RowGutter>
                    <RowGutter>
                      <Col lg={{ span: 5 }} />
                      <Col lg={{ span: 5 }} style={{ marginTop: 50 }}>
                        <div id="qr-code" style={{ display: showInfo ? 'block' : 'none' }}>
                          <ComponentToPrint ref={componentRef} />
                        </div>
                      </Col>
                    </RowGutter>
                    <RowGutter>
                      <Col lg={{ span: 5 }} />
                      <Col lg={{ span: 5 }} style={{ display: showInfo ? 'block' : 'none' }}>
                        <div className="App">
                          <Button
                            href={base64.base64}
                            color="transparent"
                            target="_blank"
                            download
                          >Download
                          </Button>
                        </div>
                      </Col>
                    </RowGutter>
                  </Drawer>
                </Col>
              </RowGutter>
            </form>
          </>
          ) }
          {step === 2 && (
            <>
              <Result
                status="success"
                title="QR code gerado com sucesso!"
                subTitle="Consulte outro cpf"
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      setCpf({});
                      setValue({});
                      setError({ field: '', message: '' });
                      setStep(0);
                      setViewEdit(false);
                    }}
                  >
                    Consultar novo cpf
                  </Button>,

                ]}
              />
            </>
          )}
        </Col>
      </Container>
    </>
  );
}
