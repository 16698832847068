import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Typography, Tooltip, Upload, Button, message, Steps, Divider, Result, Radio,
} from 'antd';
import { IoIosHelpCircle } from 'react-icons/io';
import InputMask from 'react-input-mask';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { Container } from './styles';
import { Creators as ProductCreators } from '~/store/ducks/product';
import 'bootstrap/dist/css/bootstrap.css';
import { BIGDATA_API_KEY, getToken } from '~/services/auth';
import { HandleMoneyToFloat, HandleFormatMoney, typeNumberInt } from '~/services/utils';
import socket from '~/services/notification';
import {
  RowGutter, Col, Label, RawInput,
} from '~/styles/components';
import api from '~/services/api';

const { Step } = Steps;
const { Dragger } = Upload;

export default function Main() {
  const dispatch = useDispatch();
  const io = socket();
  const [inputFields, setInputFields] = useState([
    {
      cpf: '', value: '', cashback: '', valueFinal: '',
    },
  ]);
  const history = useHistory();
  const [nameClient, setName] = useState([]);
  const [foneClient, setFone] = useState([]);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState([]);
  const [step, setStep] = useState(0);
  const [product, setProduct] = useState({});
  const [cashback, setCash] = useState({});
  const [error, setError] = useState({ field: '', message: '' });
  const [radio, setValue] = React.useState(1);


  useEffect(() => {
    dispatch(ProductCreators.getRequestProduct());
    io.on('REMOVE_CART', () => {
      dispatch(ProductCreators.getRequestBackgroundProduct());
    });

    io.on('ADD_CART', () => {
      dispatch(ProductCreators.getRequestBackgroundProduct());
    });
  }, [dispatch, io]);

  const onChange = (e) => {
    setValue(e.target.value);
    if (e.target.value == 2) {
      inputFields.value = HandleFormatMoney(Number(inputFields.valueFinal) - ((Number(inputFields.cashback) / 100) * Number(inputFields.valueFinal)));
      setCash((Number(inputFields.cashback) / 100) * Number(inputFields.valueFinal));
      // console.log("%%%%%%%%%%");
    } else {
      inputFields.value = HandleFormatMoney(Number(inputFields.valueFinal) - Number(inputFields.cashback));
      setCash(Number(inputFields.valueFinal) - Number(inputFields.cashback));
      // console.log("$$$$$$$$$$");
    }
  };

  async function searchName(cpf) {
    const { data: nome } = await Axios.get(`https://datalake.qesh.ai/api/v1/people?document=${cpf}`, {
      headers: {
        Authorization: BIGDATA_API_KEY,
      },
    });
    return (nome.Result[0].BasicData.Name);
  }

  async function searchClienByDocument(cpf) {
    const mail = localStorage.getItem('Email');
    const { data: userShop } = await api.get(`/users/email?email=${mail}`);
    const { data: clientShop } = await Axios.get(`https://ecommerce.qesh.ai/clients/${cpf}`, {
      headers: {
        user: userShop.id,
      },
    });
    return (clientShop);
  }

  async function handleInputProduct(e, index) {
    const { name, value } = e.target;

    setProduct({
      ...product,
      [name]: value,
    });

    if (name === 'cpf') {
      inputFields.cpf = value;
      const cpf = (value).replace(/[^0-9]/g, '');
      if (cpf.length == 11) {
        const nome = await searchName(cpf);
        try {
          const clientShop = await searchClienByDocument(cpf);
          setFone(clientShop.phone.replace(/^\+55/, ''));
        } catch (err) {
          console.log(err);
        }
        setName(nome);
      } else {
        setName('');
      }
    } else if (name === 'foneClient') {
      setFone(value);
    } else if (name === 'cashback') {
      inputFields.cashback = value;
    } else if (name === 'valueFinal') {
      inputFields.valueFinal = value;
    }
    if (inputFields.valueFinal && inputFields.cashback) {
      let dif = 0;
      if (radio == 2) {
        dif = Number(inputFields.valueFinal) - ((Number(inputFields.cashback) / 100) * Number(inputFields.valueFinal));
      } else {
        dif = Number(inputFields.valueFinal) - Number(inputFields.cashback);
      }
      if (dif < 0) {
        setError({ field: 'cashback', message: 'O valor de cashback não pode ser maior que o valor do produto.' });
        // message.error('O valor de cashback não pode ser maior que o valor do produto.');
      } else {
        setError({ field: 'cashback', message: '' });

        if (radio == 2) {
          inputFields.value = HandleFormatMoney(Number(inputFields.valueFinal) - ((Number(inputFields.cashback) / 100) * Number(inputFields.valueFinal)));
          setCash((Number(inputFields.cashback) / 100) * Number(inputFields.valueFinal));

          // console.log("%%%%%%%%%%");
        } else {
          inputFields.value = HandleFormatMoney(Number(inputFields.valueFinal) - Number(inputFields.cashback));
          setCash(Number(inputFields.cashback));
          // console.log("$$$$$$$$$$");
        }
      }
    }
  }


  async function nextStepProduct(e) {
    e.preventDefault();
    setError({ field: '', message: '' });

    if (!inputFields.cpf) {
      setError({ field: 'cpf', message: 'Adicione um cpf válido.' });
      message.error('Há erros no formulário, por favor corrija e tente novamente');
      return;
    }

    if (!inputFields.cashback) {
      setError({ field: 'cashback', message: 'Adicione um valor para o cashback.' });
      message.error('Há erros no formulário, por favor corrija e tente novamente');
      return;
    }

    if (!inputFields.valueFinal) {
      setError({ field: 'valueFinal', message: 'Adicione um valor total para o produto.' });
      message.error('Há erros no formulário, por favor corrija e tente novamente');
      return;
    }

    setLoading(true);
    try {
      setPrice({ price: inputFields.valueFinal });

      const cpf = (inputFields.cpf).replace(/[^0-9]/g, '');

      const token = localStorage.getItem('@qeshecommerce-token');

      const mail = localStorage.getItem('Email');

      const { data: User } = await api.get(`/users/email?email=${mail}`);

      console.log(cashback);

      const { data: avulsa } = await Axios.post(`https://cashback.qesh.ai/cashback/${User.product_name}/balance`, {
        document: cpf,
        name: nameClient,
        phone: foneClient,
        description: `Compra em ${User.name}`,
        amount: Number(cashback),
        shop_id: User.id,
        origin: 'SINGLE',
        order_total: inputFields.valueFinal,
      }, {
        headers: {
          admin: '6daaa86c2472418ce8b4a8e8790d8c1e',
        },
      });

      console.log(token);

      const { data: extrato } = await Axios.post('https://ecommerce.qesh.ai/admin/clients/external-purchases', {
        document: cpf,
        description: `Compra em ${User.name}`,
        total_value: inputFields.valueFinal,
        cashback: Number(cashback),
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(extrato);

      setStep(step + 1); // ocultar imagem
      setLoading(false);
    } catch (err) {
      message.error('Não foi possível realizar a venda.');
      console.log(err);
      console.log('\n');
      console.log(err.response.data);
      setLoading(false);
    }
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    marginLeft: '20px',
  };

  return (
    <>
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Typography.Title level={3}>Nova venda avulsa</Typography.Title>
        </Col>

        <Col md={{ span: 24 }} lg={{ span: 24 }} style={{ background: '#FFFFFF', padding: 20, borderRadius: 4 }}>
          <Steps current={step}>
            <Step title="Dados da venda" description="" />
            {/* <Step title="Imagem" subTitle="" description="" /> */}
            <Step title="Concluído" description="" />
          </Steps>

          <Divider />

          {step === 0 && (
            <>
              <form onSubmit={nextStepProduct}>

                <RowGutter>
                  <Col lg={{ span: 5 }}>
                    <div>
                      <Label>CPF:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="CPF do cliente"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <InputMask
                        placeholder="___.___.___-__"
                        mask="999.999.999-99"
                        name="cpf"
                        value={inputFields.cpf}
                        onChange={handleInputProduct}
                      />
                      {error && error.field === 'cpf' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 5 }}>
                    <div>
                      <Label>Nome:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Nome do cliente"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <InputMask
                        // placeholder="Nome Completo"
                        name="nameClient"
                        value={nameClient}
                        onChange={handleInputProduct}
                        // disabled

                      />
                      {error && error.field === 'nameClient' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 6 }}>
                    <div>
                      <Label>Telefone:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Telefone do cliente"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <InputMask
                        placeholder="(__) _____-____"
                        mask="(99) 99999-9999"
                        name="foneClient"
                        value={foneClient}
                        onChange={handleInputProduct}

                      />
                      {error && error.field === 'nameClient' && (
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          {error.message}
                        </div>
                      )}
                    </div>
                  </Col>
                </RowGutter>
                <RowGutter>
                  <Col lg={{ span: 5 }}>
                    <div>
                      <Label>Valor Total da Compra:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Valor total da venda avulsa"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <InputMask
                        min="0"
                        placeholder="R$"
                        type="number"
                        name="valueFinal"
                        value={inputFields.valueFinal}
                        onChange={handleInputProduct}
                      />
                      {error && error.field === 'valueFinal' && (
                      <div style={{ color: 'red', textAlign: 'center' }}>
                        {error.message}
                      </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 5 }}>
                    <div>
                      <Radio.Group onChange={onChange} value={radio}>
                        <Radio style={radioStyle} value={1}>Valor real</Radio>
                        <Radio style={radioStyle} value={2}>Porcentagem</Radio>
                      </Radio.Group>
                      <Tooltip
                        arrowPointAtCenter
                        placement="topLeft"
                        title="Escolha entre cashback em porcentagem sobre o valor total ou cashback em valor efetivo."
                      >
                        <IoIosHelpCircle />
                      </Tooltip>
                    </div>
                  </Col>
                  <Col lg={{ span: 5 }}>
                    <div>
                      <Label>Cashback:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Valor do cashback da venda"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <InputMask
                        min="0"
                        placeholder={radio == 1 ? 'R$ ' : ' %'}
                        type="number"
                        name="cashback"
                        value={inputFields.cashback}
                        onChange={handleInputProduct}
                      />
                      {error && error.field === 'cashback' && (
                      <div style={{ color: 'red', textAlign: 'center' }}>
                        {error.message}
                      </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={{ span: 5 }}>
                    <div>
                      <Label>Valor do Estabelecimento:
                        <Tooltip
                          arrowPointAtCenter
                          placement="topLeft"
                          title="Valor total da venda avulsa sem cashback"
                        >
                          <IoIosHelpCircle />
                        </Tooltip>
                      </Label>
                      <InputMask
                        placeholder="R$"
                        name="value"
                        value={inputFields.value}
                        onChange={handleInputProduct}
                        disabled
                      />
                      {error && error.field === 'value' && (
                      <div style={{ color: 'red', textAlign: 'center' }}>
                        {error.message}
                      </div>
                      )}
                    </div>
                  </Col>
                </RowGutter>
                <RowGutter>
                  <Col lg={{ span: 24 }}>
                    <div style={{ marginTop: 30 }}>
                      <Button htmlType="submit" type="primary" block size="large" onClick={nextStepProduct}>
                        {loading && <LoadingOutlined />}
                        {/* Próximo passo */}
                        Finalizar
                      </Button>
                    </div>
                  </Col>
                </RowGutter>
              </form>
            </>
          )}
          {step === 1 && (
            <>
              <Result
                status="success"
                title="Venda externa cadastrado com sucesso!"

                extra={[
                  <RowGutter>
                    <Col lg={{ span: 8 }} />
                    <Col lg={{ span: 16 }}>
                      <Label>
                        <h3 style={{ color: '#4F0112' }}>Preço total da venda R${HandleFormatMoney(price.price)}</h3>
                      </Label>
                    </Col>
                  </RowGutter>,
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      setProduct({});
                      const values = [...inputFields];
                      values.splice(0, inputFields.length);
                      values.push({
                        cpf: '', value: '', cashback: '', valueFinal: '',
                      });
                      setInputFields(values);
                      setError({ field: '', message: '' });
                      setStep(0);
                    }}
                  >
                    Realizar nova venda
                  </Button>,
                ]}
              />
            </>
          )}
        </Col>
      </Container>
    </>
  );
}
