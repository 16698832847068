import styled from "styled-components";

import { Row, Col } from "~/styles/components";

export const Container = styled(Row)`
  display: flex;
  height: 100%;
`;

export const FormContainer = styled(Col)`
  padding: 60px;
`;

export const Logo = styled.div``;

export const ImageBackground = styled(Col)`
  height: 100%;

  div {
    height: 100%;
    background-image: url(https://images.unsplash.com/photo-1563013544-824ae1b704d3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80);
    background-size: cover;
  }
`;
