import { combineReducers } from 'redux';

import user from './user';
import product from './product';
import client from './client';
import purshase from './purshase';

const reducers = combineReducers({
  user,
  product,
  client,
  purshase,
});

export default reducers;
