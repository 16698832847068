/**
  Types
 */

export const Types = {
  GET_REQUEST_PRODUCT: 'product/GET_REQUEST_PRODUCT',
  GET_REQUEST_BACKGROUND_PRODUCT: 'product/GET_REQUEST_BACKGROUND_PRODUCT',
  GET_REQUEST_BACKGROUND_PRODUCT_SEM_MSG: 'product/GET_REQUEST_BACKGROUND_PRODUCT_SEM_MSG',
  GET_SUCCESS_PRODUCT: 'product/GET_SUCCESS_PRODUCT',
  REQUEST_DELETE_PRODUCT: 'product/REQUEST_DELETE_PRODUCT',
  REQUEST_DELETE_PRODUCT_SEM_MSG: 'product/REQUEST_DELETE_PRODUCT_SEM_MSG',
  SUCCESS_DELETE_PRODUCT: 'product/SUCCESS_DELETE_PRODUCT',
};

/**
  Reducers
 */
const INITIAL_STATE = {
  loading: false,
  products: [],
};

export default function use(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_REQUEST_BACKGROUND_PRODUCT:
      return {
        ...state,
        loading: false,
      };
    case Types.GET_REQUEST_BACKGROUND_PRODUCT_SEM_MSG:
    return {
      ...state,
      loading: false,
      };
    case Types.GET_SUCCESS_PRODUCT:
      return {
        ...state,
        loading: false,
        products: action.payload.data,
      };
    case Types.REQUEST_DELETE_PRODUCT:
      return {
        ...state,
        loading: false,
      };
    case Types.REQUEST_DELETE_PRODUCT_SEM_MSG:
      return {
        ...state,
        loading: false,
      };
    case Types.SUCCESS_DELETE_PRODUCT:
      return {
        ...state,
        loading: false,
        products: action.payload.data,
      };
    default:
      return state;
  }
}

/**
 Actions
 */

export const Creators = {
  getRequestProduct: () => ({
    type: Types.GET_REQUEST_PRODUCT,
  }),
  getRequestBackgroundProduct: () => ({
    type: Types.GET_REQUEST_BACKGROUND_PRODUCT,
  }),
  getRequestBackgroundProductSemMsg: () => ({
    type: Types.GET_REQUEST_BACKGROUND_PRODUCT_SEM_MSG,
  }),
  getSuccessProduct: (data) => ({
    type: Types.GET_SUCCESS_PRODUCT,
    payload: { data },
  }),
  requestDelete: (data) => ({
    type: Types.REQUEST_DELETE_PRODUCT,
    payload: { data },
  }),
  requestDeleteSemMsg: (data) => ({
    type: Types.REQUEST_DELETE_PRODUCT_SEM_MSG,
    payload: { data },
  }),
  successDelete: (data) => ({
    type: Types.SUCCESS_DELETE_PRODUCT,
    payload: { data },
  }),
};
