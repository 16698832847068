import styled from 'styled-components';

import { Row } from '~/styles/components';

export const Container = styled(Row)`
  /* background: #f3f3f3; */
`;

export const StatisticContainer = styled.div`
  background: #ffffff;
  padding: 30px;
  border-radius: 2px;
`;
