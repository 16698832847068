import { call, put } from 'redux-saga/effects';


import api from '~/services/api';

import { Creators as PurshaseAction } from '~/store/ducks/purshase';

export function* requestPurshase() {
  try {
    const { data } = yield call(api.get, 'admin/clients/purshases');


    yield put(PurshaseAction.getSuccessPurshase(data));
  } catch (err) {
    //
  }
}
