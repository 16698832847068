import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Skeleton, Table, Typography, Tabs, Button, Drawer,
} from 'antd';

import Axios from 'axios';
import Moment from 'moment';
import { Container } from './styles';
import { Col } from '~/styles/components';

import { Creators as PurshaseCreators } from '~/store/ducks/purshase';

import socket from '~/services/notification';

import { HandleFormatMoney } from '~/services/utils';


import api from '~/services/api';

const { TabPane } = Tabs;


export default function NovoProdutoLote() {
  const dispatch = useDispatch();
  const io = socket();
  const { purshases, loading } = useSelector((state) => state.purshase);
  const [dataSource, setDataSouce] = useState([]);
  const [moreDate, setMoreData] = useState({});
  const [loadSource, setLoadSource] = useState(false);
  const [loadSourceSales, setLoadSourceSales] = useState(false);
  const { sales, setSales } = useState([]);
  const [dataSales, setDataSales] = useState([]);

  useEffect(() => {
    dispatch(PurshaseCreators.getRequestPurshase());
    io.on('PURSHASE', () => {
      dispatch(PurshaseCreators.getRequestBackgroundPurshase());
    });
  }, [dispatch, io]);

  function mapSource(purshasesParams) {
    function parseStatus(status) {
      if (status === 'incomplete') {
        return 'Aguardando informações';
      }

      if (status === 'awaiting') {
        return 'Aguardando pagamento';
      }

      if (status === 'expired') {
        return 'Pedido expirado';
      }

      if (status === 'done') {
        return 'Pagamento efetuado';
      }

      return status;
    }
    if (purshasesParams.length) {
      const source = purshasesParams.map((purshase, index) => ({
        key: index,
        id: purshase.id,
        name: purshase.client.name || '-',
        phone: purshase.client.phone,
        payment_type: purshase.payment_type === 'billet' ? 'Boleto' : 'Cartão',
        cart: false,
        total: `R$ ${HandleFormatMoney(purshase.products.total)}`,
        status: parseStatus(purshase.status),
        list: purshase.list || false,
      }));

      return source;
    }

    return [];
  }

  async function mapSalesSource(token) {
    if (token) {
      const { data: User } = await api.get(`/users/email?email=${token}`);

      const tok = localStorage.getItem('@qeshecommerce-token');

      const { data: extrato } = await Axios.get('https://ecommerce.qesh.ai/admin/clients/external-purchases', {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
      });


      const source = extrato.map((sales, index) => ({
        key: index,
        document: sales.document || '-',
        description: sales.description,
        total_value: `R$ ${HandleFormatMoney(sales.total_value)}`,
        cashback: `R$ ${HandleFormatMoney(sales.cashback)}`,
      }));
      setDataSales(source);
    }

    return [];
  }

  useEffect(() => {
    if (!loadSource) {
      const filteredOne = purshases.filter((p) => p.status !== 'done');
      const filtered = filteredOne.filter((p) => p.status !== 'expired');
      const source = mapSource(filtered);
      setDataSouce(source);
      setLoadSource(true);
    }
  }, [purshases]);

  useEffect(() => {
    if (!loadSourceSales) {
      const mail = localStorage.getItem('Email');
      mapSalesSource(mail);
      setLoadSourceSales(true);
    }
  }, [sales]);


  function getDetails(purshaseId) {
    const purshase = purshases.find((p) => p.id === purshaseId);

    setMoreData(purshase);
  }

  function onClose() {
    setMoreData({});
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Forma de pagamento',
      dataIndex: 'payment_type',
      key: 'payment_type',
      // sorter: (a, b) => (a.price > b.price ? 1 : -1),
      render: (text) => <div style={{ marginLeft: '50px' }}>{text}</div>,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      sorter: (a, b) => (a.total < b.total ? -1 : 1),
      render: (text, client) => (
        <span style={{
          color: client.total ? 'green' : 'inherit',
          fontWeight: client.total ? 'bold' : 'normal',
        }}
        >{text}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Ações',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, purshase) => (
        <span>
          <Button type="primary" style={{ cursor: 'pointer' }} onClick={() => getDetails(purshase.id)}>
            Detalhes
          </Button>
        </span>
      ),
    },
    {
      title: 'Imprimir',
      dataIndex: 'imprimir',
      key: 'imprimir',
      render: (text, purshase) => (
        <span>
          {purshase.list ? (
            <Button
              type="primary"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  `https://ecommerce.qesh.ai/files/${purshase.list}`,
                  '_blank',
                );
              }}
            >
              Imprimir
            </Button>
          ) : (
            '-'
          )}
        </span>
      ),
    },
  ];


  const columns2 = [
    {
      title: 'Documento',
      dataIndex: 'document',
      key: 'document',
      width: '30%',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Total',
      dataIndex: 'total_value',
      key: 'total_value',
      sorter: (a, b) => (a.total_value < b.total_value ? -1 : 1),
      render: (text, client) => (
        <span style={{
          color: client.total_value ? 'green' : 'inherit',
          fontWeight: client.total_value ? 'bold' : 'normal',
        }}
        >{text}
        </span>
      ),
    },
    {
      title: 'Cashback',
      dataIndex: 'cashback',
      key: 'cashback',
      sorter: (a, b) => (a.cashback < b.cashback ? -1 : 1),
      render: (text, client) => (
        <span style={{
          color: client.cashback ? 'green' : 'inherit',
          fontWeight: client.cashback ? 'bold' : 'normal',
        }}
        >{text}
        </span>
      ),
    },

  ];


  const callback = (id) => {
    if (id === 1 || id === '1') {
      const filteredOne = purshases.filter((p) => p.status !== 'done');
      const filtered = filteredOne.filter((p) => p.status !== 'expired');
      const source = mapSource(filtered);
      setDataSouce([...source]);
    }
    if (id === 2 || id === '2') {
      const filtered = purshases.filter((p) => p.status === 'done');
      const source = mapSource(filtered);
      setDataSouce([...source]);
    }
    if (id === 3 || id === '3') {
      const filtered = purshases.filter((p) => p.status === 'expired');
      const source = mapSource(filtered);
      setDataSouce([...source]);
    }
  };

  return (
    <>
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Typography.Title level={3}>Vendas Concluídas</Typography.Title>
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Skeleton active paragraph={{ rows: 8 }} loading={loading}>
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Aguardando pagamento" key="1">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{ position: ['topRight', 'bottomRight'] }}
                  scroll={{ y: 400, scrollToFirstRowOnChange: true }}
                />
              </TabPane>
              <TabPane tab="Pagamento confirmado" key="2">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{ position: ['topRight', 'bottomRight'] }}
                  scroll={{ y: 400, scrollToFirstRowOnChange: true }}
                />
              </TabPane>
              <TabPane tab="Pedido expirado" key="3">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{ position: ['topRight', 'bottomRight'] }}
                  scroll={{ y: 400, scrollToFirstRowOnChange: true }}
                />
              </TabPane>
              <TabPane tab="Vendas externas/avulsas" key="4" loading={setSales}>
                <Table
                  columns={columns2}
                  dataSource={dataSales}
                  pagination={{ position: ['topRight', 'bottomRight'] }}
                  scroll={{ y: 400, scrollToFirstRowOnChange: true }}
                />
              </TabPane>
            </Tabs>
          </Skeleton>
        </Col>
      </Container>
    </>
  );
}
