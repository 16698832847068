import { Table, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HandleFormatMoney } from '~/services/utils';
import { Col, Row } from '~/styles/components';
import { Container } from './styles';

export default function Mensalidades() {
  const { user } = useSelector((state) => state.user);
  const [months] = useState([
    'JANEIRO',
    'FEVEREIRO',
    'MARÇO',
    'ABRIL',
    'MAIO',
    'JUNHO',
    'JULHO',
    'AGOSTO',
    'SETEMBRO',
    'OUTUBRO',
    'NOVEMBRO',
    'DEZEMBRO',
  ]);
  const [cashbackCount, setCashbackCount] = useState(null);
  const [info, setInfo] = useState(null);

  const updateCashbackValues = () => {
    Axios.get(`https://cashback.qesh.ai/cashback/${user.product_name}/balance/count?start=${moment().startOf('month').toISOString()}&end=${moment().endOf('month').toISOString()}&shop_id=${user.id}`, {
      headers: {
        admin: '6daaa86c2472418ce8b4a8e8790d8c1e',
      },
    }).then((res) => {
      setCashbackCount(res.data?.count);
    }).catch((err) => console.log(err));

    Axios.get(`https://cashback.qesh.ai/cashback-dates/${user.product_name}`, {
      headers: {
        admin: '6daaa86c2472418ce8b4a8e8790d8c1e',
      },
    }).then((res) => {
      setInfo(res.data?.data);
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    updateCashbackValues();
    setInterval(updateCashbackValues, 30000);
  }, []);


  return (
    <Container style={{ marginTop: 50 }}>
      <Col md={{ span: 24 }} lg={{ span: 24 }}>
        <Typography.Title level={3}>Mensalidades</Typography.Title>
      </Col>

      <div style={{
        display: 'flex', padding: 10, justifyContent: 'space-between', width: 800,
      }}
      >
        {info !== null && cashbackCount !== null && (
        <table style={{ width: 300, backgroundColor: 'white' }}>
          <tr>
            <th>Mensalidade</th>
            <th>Valor</th>
          </tr>
          <tr>
            <td>{months[moment().month()]}</td>
            <td>R$ {HandleFormatMoney(+info.monthly_payment)}</td>
          </tr>
        </table>
        )}

        {info !== null && cashbackCount !== null && (
        <table style={{ width: 300, backgroundColor: 'white' }}>
          <tr>
            <th>Meta</th>
            <th>Realizado</th>
          </tr>
          <tr>
            <td>{info.goal}</td>
            <td>{cashbackCount}</td>
          </tr>
        </table>
        )}
      </div>

    </Container>
  );
}
