import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Col } from '~/styles/components';
import Trello from '../../components/Trello';

import { useHistory } from 'react-router-dom';

import { Button, Popover } from 'antd';

import { BiPlusMedical } from 'react-icons/bi';

import { Creators as ClientCreators } from '~/store/ducks/client';
import { Creators as PurshaseCreators } from '~/store/ducks/purshase';
import { Creators as ProductCreators } from '~/store/ducks/product';

import socket from '~/services/notification';

import { Container } from './styles';
import { HandleFormatMoney } from '~/services/utils';

import { getToken } from '../../services/auth';

export default function NovoProdutoLote() {
  const dispatch = useDispatch();
  const audio = new Audio("https://trello-attachments.s3.amazonaws.com/5d35d89cefbb91439aa68b30/5f073a6a48dd1544f6482272/bece99fb4c1bee6cab56d3085b679dab/cash.mp3");
  const [play, setPlay]= useState(false);
  const io = socket();
  const history = useHistory();

  const togglePlay = () => {
    if (!play){
      audio.play();
      setPlay(true);
      setInterval(function(){ 
        setPlay(false);
      }, audio.duration * 1000);
    }
    
  }

  const [lists, setLists] = useState(() => [
    {
      id: 1,
      title: 'Clientes em compra',
      creatable: false,
      cards: [],
    },
    {
      id: 2,
      title: 'Pedidos Recebidos',
      creatable: false,
      cards: [],
    },
    {
      id: 3,
      title: 'Pagamento Recebido',
      creatable: false,
      cards: [],
    },
    {
      id: 4,
      title: 'Enviados para entrega',
      creatable: false,
      cards: [{
        id: -1,
        content: 'invisible',
        labels: [],
        data: { result: false },
      }],
    },
    {
      id: 5,
      title: 'Problemas na entrega',
      creatable: false,
      cards: [{
        id: -2,
        content: 'invisible',
        labels: [],
        data: { result: false },
      }],
    },
    {
      id: 6,
      title: 'Entregues',
      creatable: false,
      cards: [{
        id: -3,
        content: 'invisible',
        labels: [],
        data: { result: false },
      }],
    },

  ]);

  const { clients } = useSelector((state) => state.client);
  const { purshases } = useSelector((state) => state.purshase);
  const [inPurchase, setInPurchase] = useState([]);


  const [purchasesAwaiting, setPurchasesAwaiting] = useState([]);
  const [purchasesPayment, setPurchasesPayment] = useState([]);

  const [sentDelivery, setSendDelivery] = useState([]);
  const [delivered, setDelivered] = useState([]);
  const [failureDelivered, setFailureDelivered] = useState([]);


  const awaitPayment = useCallback(() => {
    const awaiting = purshases.filter((purc) => purc.status === 'awaiting'
    || purc.status === 'incomplete');

    const hasPayment = purshases.filter((purc) => purc.status === 'done'
    && (purc.loggi_status === 'allocating' || String(purc.loggi_status).length <= 0));


    const sourceAwaiting = awaiting.map((purshase, index) => ({
      key: index,
      id: purshase.id,
      name: purshase.client.name || '-',
      phone: purshase.client.phone,
      payment_type: purshase.payment_type === 'billet' ? 'Boleto' : 'Cartão',
      total: `R$ ${HandleFormatMoney(purshase.products.total)}`,
      list: purshase.list || false,
      result: purshase,
    }));

    const sourcePayment = hasPayment.map((purshase, index) => ({
      key: index,
      id: purshase.id,
      name: purshase.client.name || '-',
      phone: purshase.client.phone,
      payment_type: purshase.payment_type === 'billet' ? 'Boleto' : 'Cartão',
      total: `R$ ${HandleFormatMoney(purshase.products.total)}`,
      list: purshase.list || false,
      result: purshase,
    }));

    setPurchasesAwaiting(sourceAwaiting);
    setPurchasesPayment(sourcePayment);
  }, [purshases]);


  useEffect(() => {
    const inPurchasesClient = clients.filter((c) => (!!(c.cart && c.cart.products.items.length > 0)));
    setInPurchase([...inPurchasesClient]);
  }, [clients]);

  const checkSendDelivery = useCallback(() => {
    const sendDeliveryL = purshases.filter((purc) => (purc.status === 'done'
     ||  purc.status === 'sent_for_delivery') );

    const deliveredL = purshases.filter((purc) => purc.status === 'delivered');

    const deliveredFailure = purshases.filter((purc) => purc.status === 'failure');

    const sourceAwaiting = sendDeliveryL.map((purshase, index) => ({
      key: index,
      id: purshase.id,
      name: purshase.client.name || '-',
      phone: purshase.client.phone,
      payment_type: purshase.payment_type === 'billet' ? 'Boleto' : 'Cartão',
      total: `R$ ${HandleFormatMoney(purshase.products.total)}`,
      list: purshase.list || false,
      result: purshase,
    }));

    const sourceDelivered = deliveredL.map((purshase, index) => ({
      key: index,
      id: purshase.id,
      name: purshase.client.name || '-',
      phone: purshase.client.phone,
      payment_type: purshase.payment_type === 'billet' ? 'Boleto' : 'Cartão',
      total: `R$ ${HandleFormatMoney(purshase.products.total)}`,
      list: purshase.list || false,
      result: purshase,
    }));

    const sourceDeliveredFailure = deliveredFailure.map((purshase, index) => ({
      key: index,
      id: purshase.id,
      name: purshase.client.name || '-',
      phone: purshase.client.phone,
      payment_type: purshase.payment_type === 'billet' ? 'Boleto' : 'Cartão',
      total: `R$ ${HandleFormatMoney(purshase.products.total)}`,
      list: purshase.list || false,
      result: purshase,
    }));

    setSendDelivery(sourceAwaiting);
    setDelivered(sourceDelivered);
    setFailureDelivered(sourceDeliveredFailure);
  }, [purshases]);


  useEffect(() => {
    awaitPayment();
    checkSendDelivery();
  }, [clients, purshases]);


  const makeTrello = useCallback(() => {
    const newLists = lists;

    // Clientes em compras
    const OrdersinPurchase = inPurchase.map((p) => ({
      id: p.id,
      content: p.name || p.phone || 'Sem nome cadastrado\n',
      value: `Total parcial: ${HandleFormatMoney(p.cart.products.total)}`,
      labels: ['#7160C1'],
      data: { ...p },
    }));

    // Aguardando pagamento
    const ordersReceived = purchasesAwaiting.map((p) => ({
      id: p.id,
      content: p.name,
      value: `Total: ${p.total}`,
      labels: ['#f16f11'],
      data: { ...p },
    }));

    // Pagamento recebido
    const ordersPayments = purchasesPayment.map((p) => ({
      id: p.id,
      content: p.name,
      value: `Total: ${p.total}`,
      labels: ['#73E29B'],
      receipt: true,
      data: { ...p },
    }));

    // enviados para entrega
    const sendDeliveryd = sentDelivery.map((p) => ({
      id: p.id,
      content: p.name,
      value: `Total: ${p.total}`,
      labels: ['#73E29B'],
      receipt: true,
      data: { ...p },
    }));

    // problemas na entrega
    const FailureDelivered = failureDelivered.map((p) => ({
      id: p.id,
      content: p.name,
      value: `Total: ${p.total}`,
      labels: ['#73E29B'],
      receipt: true,
      data: { ...p },
    }));

    // Entregue
    const successDelivered = delivered.map((p) => ({
      id: p.id,
      content: p.name,
      value: `Total: ${p.total}`,
      labels: ['#73E29B'],
      receipt: true,
      data: { ...p },
    }));


    if (OrdersinPurchase.length > -1) {
      newLists[0].cards = OrdersinPurchase;
    }

    if (ordersReceived.length > -1) {
      newLists[1].cards = ordersReceived;
    }

    if (ordersPayments.length > -1) {
      newLists[2].cards = ordersPayments;
    }

    if (sendDeliveryd.length > 0) {
      newLists[3].cards = sendDeliveryd;
    }

    if (FailureDelivered.length > 0) {
      newLists[4].cards = FailureDelivered;
    }

    if (successDelivered.length > 0) {
      newLists[5].cards = successDelivered;
    }

    setLists([...newLists]);
  }, [purchasesAwaiting, purchasesPayment, inPurchase]);

  useEffect(() => {
    makeTrello();
  }, [purchasesAwaiting, purchasesPayment, inPurchase]);

  useEffect(() => {
    dispatch(ProductCreators.getRequestProduct());
    dispatch(ClientCreators.getRequestClient());
    dispatch(PurshaseCreators.getRequestPurshase());

    io.on('REMOVE_CART', () => {
      dispatch(ClientCreators.getRequestBackgroundClient());
      makeTrello();
    });
    io.on('ADD_CART', () => {
      dispatch(ClientCreators.getRequestBackgroundClient());
      makeTrello();
    });
    io.on('PURSHASE', () => {
      dispatch(ClientCreators.getRequestBackgroundClient());
      dispatch(PurshaseCreators.getRequestBackgroundPurshase());
      dispatch(ProductCreators.getRequestBackgroundProduct());
      awaitPayment();
      checkSendDelivery();
      makeTrello();
    });

    if (localStorage.getItem('sound' === null)){
      localStorage.setItem('sound', true);
    }

    io.on('PAYMENT', () => {
      console.log(localStorage.getItem('sound'));
      if (getToken()){
        if (localStorage.getItem('sound') === true || localStorage.getItem('sound') === 'true'){
          togglePlay();
        }
      }
    });
  }, [dispatch, io]);

  // useEffect(() => {
  //   setInterval(() => {
  //     console.log('chamando...');
  //     dispatch(ClientCreators.getRequestBackgroundClient());
  //     dispatch(PurshaseCreators.getRequestBackgroundPurshase());
  //     dispatch(ProductCreators.getRequestBackgroundProduct());
  //     awaitPayment();
  //     checkSendDelivery();
  //     makeTrello();
  //   }, 300000);
  // }, [checkSendDelivery]);


  return (
    <>
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }} style={{ marginTop: 20, borderRadius: 4 }}>
          <Col md={{ span: 5 }} lg={{ span: 5 }} >
            <Popover content={"Cadastrar nova venda externa"}>
              <Button type="primary" shape="round" size="large" icon={<BiPlusMedical /> } onClick={() => history.push('/vendas/externa')}>
              <span style={{marginLeft: 5, marginRight: 4}}>Nova Venda</span>
              </Button>
            </Popover>
          </Col>
          <Col md={{ span: 26 }} lg={{ span: 26 }} style={{ background: '#f5f5f5' }}>
            <Trello data={lists} />
          </Col>
        </Col>
      </Container>
    </>
  );
}
