import styled from 'styled-components';

import { Row } from '~/styles/components';

export const Container = styled(Row)`
  
`;

export const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  marginLeft: '20px',
};
