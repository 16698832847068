import styled from 'styled-components';

import { Layout, Menu as AntdMenu } from 'antd';


export const Container = styled(Layout.Sider).attrs({
  breakpoint: 'md',
  collapsedWidth: '0',
})`

  background: #FFFFFF;

  display: none;

  @media (max-width: 768px) {
    display: inherit;
  }

  .ant-layout-sider-zero-width-trigger {
    top: 20px;
    background: #1890ff;
  }
  .ant-layout-sider-zero-width-trigger:hover {
    background: #1890ff;
  }
`;

export const Logo = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const Menu = styled(AntdMenu).attrs({

})`
  li {}
`;
