export const TOKEN_KEY = '@qeshecommerce-token';

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);

  let user;
  if (localStorage.user) {
    localStorage.getItem(user);
    localStorage.removeItem(user);
  }
};

export const isAuthenticated = () => {
  if (!localStorage.getItem(TOKEN_KEY)) {
    return null;
  }

  return localStorage.getItem(TOKEN_KEY) !== null;
};

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const BIGDATA_API_KEY = 'CA08482FC1E845972464F42DF08454743E34884E0FFCF088C1D26876AC7BD292';
