import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Skeleton, Table, Typography, Tabs, Button, Drawer,
} from 'antd';

import { Container } from './styles';
import { Col } from '~/styles/components';

import { Creators as PurshaseCreators } from '~/store/ducks/purshase';

import socket from '~/services/notification';

import { HandleFormatMoney } from '~/services/utils';

import Axios from 'axios';

import Moment from 'moment'

import api from '~/services/api';

const { TabPane } = Tabs;


export default function NovoProdutoLote() {
  const dispatch = useDispatch();
  const io = socket();
  const { purshases, loading } = useSelector((state) => state.purshase);
  const { salesScheduled, setSalesScheduled} = useState([]);
  const { sales, setSales} = useState([]);
  const [dataSalesScheduled, setDataSalesScheduled] = useState([]);
  const [dataSales, setDataSales] = useState([]);
  const [moreDate, setMoreData] = useState({});
  const [loadSource, setLoadSource] = useState(false);

  useEffect(() => {
    dispatch(PurshaseCreators.getRequestPurshase());
    io.on('PURSHASE', () => {
      dispatch(PurshaseCreators.getRequestBackgroundPurshase());
    });
  }, [dispatch, io]);

  async function mapSalesSource(token) {
    if (token) {
      let { data: User } = await api.get(`/users/email?email=${token}`);     


      const {data: extrato} = await Axios.get(`https://cashback.qesh.ai/cashback/${User.product_name}/shop-transactions/${User.id}`, {headers:{
        admin: '6daaa86c2472418ce8b4a8e8790d8c1e',
      }});

      console.log("EXTRATOOOOOO");
      console.log(extrato);

      const source = extrato.map((sales, index) => ({
        key: index,
        document: sales.document || '-',
        description: sales.description,
        date: Moment(sales.updated_at).format('DD/MM/YYYY'),
        amount: `R$ ${HandleFormatMoney(sales.amount)}`,
        order_total: `R$ ${HandleFormatMoney(sales.order_total)}`,
        credit: !sales.credit, //true é entrada de dinheiro 
      }));
      setDataSales(source);
    }

    return [];
  }

  async function mapSalesSourceScheduled(token) {

    if (token) {
      let { data: User } = await api.get(`/users/email?email=${token}`);     
      const {data: agendamentos} = await Axios.get(`https://cashback.qesh.ai/cashback/${User.product_name}/shop-transactions/${User.id}/scheduled`, {headers:{
        admin: '6daaa86c2472418ce8b4a8e8790d8c1e',
      }});

      console.log("Agendamentos");
      console.log(agendamentos);
      
      const source = agendamentos.map((sales, index) => ({
        key: index,
        document: sales.document || '-',
        description: sales.description,
        date: Moment(sales.updated_at).format('DD/MM/YYYY'),
        date_scheduled: Moment(sales.updated_at).add(30, 'days').format('DD/MM/YYYY'),
        amount: `R$ ${HandleFormatMoney(sales.amount)}`,
        order_total: `R$ ${HandleFormatMoney(sales.order_total)}`,
      }));
      setDataSalesScheduled(source);
      
    }
    return [];
  }

  useEffect(() => {
    if (!loadSource){
      const mail = localStorage.getItem('Email');
      mapSalesSourceScheduled(mail);
      setLoadSource(true);
    }
  }, [salesScheduled]);

  useEffect(() => {
    if (!loadSource){
      const mail = localStorage.getItem('Email');
      mapSalesSource(mail);
      setLoadSource(true);
    }
  }, [sales]);
  
  const columns2 = [
    {
      title: 'Documento',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      
    },
    {
      title: 'Data de venda',
      dataIndex: 'date',
      key: 'date',
      // width: '30%',
    },
    {
      title: 'Data de pagamento',
      dataIndex: 'date_scheduled',
      key: 'date_scheduled',
      // width: '30%',
    },
    {
      title: 'Cashback',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => (a.amount < b.amount ? -1 : 1),
      render: (text, client) => (
        <span style={{
          // color: client.amount ? 'green' : 'inherit',
          fontWeight: client.amount ? 'bold' : 'normal',
        }}
        >{text}
        </span>
      ),
    },
    {
      title: 'Total da venda',
      dataIndex: 'order_total',
      key: 'order_total',
      sorter: (a, b) => (a.order_total < b.order_total ? -1 : 1),
      render: (text, client) => (
        <span style={{
          // color: client.order_total ? 'green' : 'inherit',
          // fontWeight: client.order_total ? 'bold' : 'normal',
        }}
        >{text}
        </span>
      ),
    },
    
  ];

  const columns = [
    {
      title: 'Documento',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'updated_at',
      // width: '30%',
    },
    {
      title: 'Cashback',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => (a.amount < b.amount ? -1 : 1),
      render: (text, client) => (
        <span style={{
          // color: client.amount ? 'green' : 'inherit',
          fontWeight: 'bold',
        }}
        >{text}
        </span>
      ),
    },
    {
      title: 'Total da venda',
      dataIndex: 'order_total',
      key: 'order_total',
      sorter: (a, b) => (a.order_total < b.order_total ? -1 : 1),
      render: (text, client) => (
        <span style={{
          // color: client.order_total ? 'green' : 'inherit',
          // fontWeight: client.order_total ? 'bold' : 'normal',
        }}
        >{text}
        </span>
      ),
    },
    {
      title: 'Movimentação',
      dataIndex: 'credit',
      key: 'credit',
      // width: '30%',
      render: (text, client) => (
        <span style={{
          color: client.credit ? 'green' : 'inherit',
          // fontWeight: 'bold',
        }}
        >{client.credit? "Entrada":"Saída"}
        </span>
      ),
    },
    
  ];


  return (
    <>
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Typography.Title level={3}>Extrato de vendas</Typography.Title>
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Skeleton active paragraph={{ rows: 8 }} loading={loading}>
            <Tabs defaultActiveKey="4" >
              <TabPane tab="Agendadas" key="4" loading={setSalesScheduled}>
                <Table
                  columns={columns2}
                  dataSource={dataSalesScheduled}
                  pagination={{ position: ['topRight', 'bottomRight'] }}
                  scroll={{ y: 400, scrollToFirstRowOnChange: true }}
                />
              </TabPane>
              <TabPane tab="Efetuadas" key="5" loading={setSales}>
                <Table
                  columns={columns}
                  dataSource={dataSales}
                  pagination={{ position: ['topRight', 'bottomRight'] }}
                  scroll={{ y: 400, scrollToFirstRowOnChange: true }}
                />
              </TabPane>
            </Tabs>
          </Skeleton>
        </Col>
        
      </Container>
    </>
  );
}
