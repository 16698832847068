import React, { useState, useEffect } from 'react';

import axios from 'axios';

import {
  Typography, Tooltip, Button, message, Tag, Modal, Space,
} from 'antd';

import { IoIosHelpCircle } from 'react-icons/io';
import { LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Container } from './styles';


import {
  RowGutter, Col, RawInput, Label,
} from '~/styles/components';

import api from '~/services/api';

import acceptsCeps from './accept.json';

export default function Main() {
  const [loading, setLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const [cepLoggi, setCepLoggi] = useState('');
  const [cepManual, setCepManual] = useState('');
  const [tempoManual, setTempoManual] = useState('');
  const [valorEntrega, setValorEntrega] = useState('');
  const [error, setError] = useState('');
  const [loggiCeps, setLoggiCeps] = useState([]);
  const [manualCeps, setManualCeps] = useState([]);

  const [accepts] = useState(() => {
    const { ceps } = acceptsCeps;
    const keys = Object.keys(ceps);
    let allCeps = [];

    keys.forEach((k) => {
      allCeps = [...allCeps, ...ceps[k]];
    });


    return allCeps;
  });

  const ReachableContext = React.createContext();
  const UnreachableContext = React.createContext();


  async function nextStepProduct(e = false, type, tempoManual) {
    if (e) {
      e.preventDefault();
    }

    setError('');
    let cepContent = '';

    if (type === 1) {
      if (!cepLoggi.length) {
        setError('CEP é obrigatório');
        return;
      }

      if (cepLoggi.length < 8 || cepLoggi.length > 8) {
        setError('CEP inválido');
        return;
      }

      cepContent = cepLoggi;

      let isPermitt = accepts.find((ac) => ac === cepContent);

      try {
        const { data: acceptLoggi } = await axios.get(`https://ecommerce.qesh.ai/notification/cep?cep=${cepContent}`);
        if (acceptLoggi && acceptLoggi.value) {
          isPermitt = true;
        } else {
          isPermitt = false;
        }
      } catch (err) {
        message.error('Não foi possível validar o CEP');
        setLoading(false);
        return;
      }

      if (!isPermitt) {
        const config = {
          title: 'CEP não disponível',
          cancelText: 'Cancelar',
          okText: 'Adicionar',
          onOk: async () => {
            const cep = await api.post('/admin/clients/cep?type=2', {
              content: cepContent,
              type: 2,
              // delivery_time:
            });
            api.get('admin/clients/cep').then(({ data }) => {
              const loggiceps = data.filter((c) => c.type === 1);
              const manualceps = data.filter((c) => c.type === 2);

              setLoggiCeps(loggiceps);
              setManualCeps(manualceps);
              setTempoManual(null);
              setCepManual('');
              setCepLoggi('');
            });
          },
          onCancel: () => false,
          content: (
            <div>
              Este CEP não pode ser adicionado na entrega automática, por que esta fora da faixa
              <br />
              de entrega disponível.
              <br />
              <br />
              Deseja adicionar na entrega <b>manual</b>?
            </div>
          ),
        };

        modal.confirm(config);
        return;
      }
    } else {
      if (!cepManual.length) {
        setError('CEP é obrigatório');
        return;
      }

      if (cepManual.length < 8 || cepManual.length > 8) {
        setError('CEP inválido');
        return;
      }

      cepContent = cepManual;
    }


    setLoading(true);
    try {
      const cep = await api.post(`/admin/clients/cep?type=${type}`, {
        content: cepContent,
        type,
        delivery_time: tempoManual,
        delivery_tax: valorEntrega,
      });

      api.get('admin/clients/cep').then(({ data }) => {
        const loggiceps = data.filter((c) => c.type === 1);
        const manualceps = data.filter((c) => c.type === 2);

        setLoggiCeps(loggiceps);
        setManualCeps(manualceps);
        console.log(cep);
        setTempoManual(null);
        setCepManual('');
        setCepLoggi('');
        setValorEntrega('');
      });

      if (cep.data.type !== type) {
        const types = {
          1: 'Automática',
          2: 'Manual',
        };
        message.warning(`CEP já está cadastrado na entrega ${types[cep.data.type]}`);
      } else {
        message.success('CEP cadastrado com sucesso');
      }

      setLoading(false);
    } catch (err) {
      message.error('Não foi possível cadastrar o cep');
      setLoading(false);
    }
  }
  async function handleDelete(id) {
    await api.delete(`admin/clients/cep/${id}`);
    message.success('CEP deletado com sucesso');


    api.get('admin/clients/cep').then(({ data }) => {
      const loggiceps = data.filter((c) => c.type === 1);
      const manualceps = data.filter((c) => c.type === 2);

      setLoggiCeps(loggiceps);
      setManualCeps(manualceps);
    });
  }


  useEffect(() => {
    api.get('admin/clients/cep').then(({ data }) => {
      const loggiceps = data.filter((c) => c.type === 1);
      const manualceps = data.filter((c) => c.type === 2);
      console.log(manualCeps);
      setLoggiCeps(loggiceps);
      setManualCeps(manualceps);
    });
  }, []);

  return (
    <>
      <Container>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Typography.Title level={3}>Novo Cep</Typography.Title>
        </Col>

        {/* <Col
          md={{ span: 12 }}
          lg={{ span: 12 }}
          style={{ background: '#FFFFFF', padding: 20, borderRadius: 4 }}
        >
          <form onSubmit={(e) => e.preventDefault()}>
            <RowGutter> */}
        {/* <Col lg={{ span: 10 }}>
                <div>
                  <Label>CEP (Entrega automática)
                    <Tooltip
                      arrowPointAtCenter
                      placement="topLeft"
                      title="Para utilizar essa função você deve ter um parceiro integrado."
                    >
                      <IoIosHelpCircle />
                    </Tooltip>
                  </Label>
                  <RawInput
                    type="number"
                    placeholder="31000000"
                    name="cep"
                    onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                    value={cepLoggi}
                    onChange={(e) => setCepLoggi(e.target.value)}
                    disabled
                    Tooltip="Desabilitado por hora."
                  />

                  {!!error && (
                  <div style={{ marginTop: 5, color: 'red', textAlign: 'center' }}>
                    {error}
                  </div>
                  )}
                </div>
              </Col> */}
        {/* <Col lg={{ span: 6 }}>
                <div style={{ marginTop: 30 }}>
                  <Button disabled htmlType="submit" type="primary" block size="large" onClick={() => nextStepProduct(false, 1, tempoManual)}>
                    {loading && <LoadingOutlined />}
                    Cadastrar CEP
                  </Button>
                </div>
              </Col>
            </RowGutter>
          </form>
        </Col> */}

        <Col
          md={{ span: 12 }}
          lg={{ span: 12 }}
          style={{ background: '#FFFFFF', padding: 20, borderRadius: 4 }}
        >
          <form onSubmit={(e) => e.preventDefault()}>
            <RowGutter>
              <Col lg={{ span: 9 }}>
                <div>
                  <Label>CEP (Entrega manual)
                    <Tooltip
                      arrowPointAtCenter
                      placement="topLeft"
                      title="Informe um CEP no qual você trabalha."
                    >
                      <IoIosHelpCircle />
                    </Tooltip>
                  </Label>
                  <RawInput
                    type="number"
                    placeholder="31000000"
                    name="cep"
                    onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                    value={cepManual}
                    onChange={(e) => setCepManual(e.target.value)}
                  />

                  {!!error && (
                  <div style={{ marginTop: 5, color: 'red', textAlign: 'center' }}>
                    {error}
                  </div>
                  )}
                </div>
              </Col>
              <Col lg={{ span: 6 }}>
                <div>
                  <Label>Tempo de Entrega
                    <Tooltip
                      arrowPointAtCenter
                      placement="topLeft"
                      title="Informe um tempo de entrega para o CEP inserido."
                    >
                      <IoIosHelpCircle />
                    </Tooltip>
                  </Label>
                  <RawInput
                    type="text"
                    placeholder="40 min"
                    name="deliveryTime"
                    // onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                    value={tempoManual}
                    onChange={(e) => setTempoManual(e.target.value)}
                  />

                  {!!error && (
                  <div style={{ marginTop: 5, color: 'red', textAlign: 'center' }}>
                    {error}
                  </div>
                  )}
                </div>
              </Col>
              <Col lg={{ span: 6 }}>
                <div>
                  <Label>Taxa de Entrega
                    <Tooltip
                      arrowPointAtCenter
                      placement="topLeft"
                      title="Informe um valor para a entrega do cep inserido"
                    >
                      <IoIosHelpCircle />
                    </Tooltip>
                  </Label>
                  <RawInput
                    type="text"
                    placeholder="2,50"
                    name="valorEntrega"
                    // onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                    value={valorEntrega}
                    onChange={(e) => setValorEntrega((e.target.value).replace(',', '.'))}
                  />

                  {!!error && (
                  <div style={{ marginTop: 5, color: 'red', textAlign: 'center' }}>
                    {error}
                  </div>
                  )}
                </div>
              </Col>
              <Col lg={{ span: 8 }}>
                <div style={{ marginTop: 30 }}>
                  <Button htmlType="submit" type="primary" block size="large" onClick={() => nextStepProduct(false, 2, tempoManual)}>
                    {loading && <LoadingOutlined />}
                    Cadastrar CEP
                  </Button>
                </div>
              </Col>
            </RowGutter>
          </form>
        </Col>

        {/* <Col
          md={{ span: 12 }}
          lg={{ span: 12 }}
          style={{
            marginTop: 20, background: '#FFFFFF', padding: 20, borderRadius: 4,
          }}
        >
          <h3>CEPS cadastrados (automática)</h3>

          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridAutoRows: 'minmax(10px, 30px)',
            gap: '2px',
          }}
          >
            {loggiCeps.map((cp) => (
              <span
                key={cp.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  maxHeight: '20px',
                  maxWidth: '180px',
                }}
              >
                {cp.content}{' '}
                <Tag
                  onClick={() => handleDelete(cp.id)}
                  style={{ cursor: 'pointer' }}
                  color="error"
                >
                  <CloseCircleOutlined style={{ textAlign: 'center' }} />
                </Tag>
              </span>
            ))}
          </div>
        </Col> */}


        <Col
          md={{ span: 12 }}
          lg={{ span: 12 }}
          style={{
            marginTop: 20, background: '#FFFFFF', padding: 20, borderRadius: 4,
          }}
        >
          <h3 style={{ marginBottom: '16px' }}>CEPS cadastrados (manual)</h3>

          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridAutoRows: 'minmax(10px, 30px)',
            gap: '4px',
          }}
          >
            {manualCeps.map((cp) => (
              <span
                key={cp.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  maxHeight: '20px',
                }}
              >
                {cp.content} {cp.delivery_time ? `(${cp.delivery_time})` : ''} {cp.delivery_tax ? `- R$ ${cp.delivery_tax}` : '- Grátis'}
                <Tag
                  onClick={() => handleDelete(cp.id)}
                  style={{
                    cursor: 'pointer', display: 'flex', justifyContent: 'center', height: '25px', alignItems: 'center',
                  }}
                  color="error"
                >
                  <CloseCircleOutlined style={{ textAlign: 'center' }} />
                </Tag>
              </span>
            ))}
          </div>
        </Col>

      </Container>

      <ReachableContext.Provider value="Light">
        <Space>
          {contextHolder}
        </Space>
      </ReachableContext.Provider>
    </>
  );
}
