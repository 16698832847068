import React from 'react';
import { useHistory } from 'react-router-dom';


import {
  Typography, Upload, Button, message,
} from 'antd';

import { InboxOutlined } from '@ant-design/icons';
import { Container } from './styles';

import { getToken } from '~/services/auth';
import { Col } from '~/styles/components';

const { Dragger } = Upload;


export default function NovoProdutoLote() {
  const history = useHistory();

  const props = {
    name: 'excel',
    multiple: false,
    action: 'https://ecommerce.qesh.ai/products/excel',
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`Produtos importado com sucesso. [${info.file.response.length}] produtos cadastrados`);
        history.push('/estoque');
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} erro ao fazer upload: ${info.file.response.message}`);
      }
    },
  };

  return (
    <>
      <Container>
        <Col md={{ span: 18 }} lg={{ span: 18 }}>
          <Typography.Title level={3}>Novo Produto em Lote</Typography.Title>
        </Col>
        <Col md={{ span: 6 }} lg={{ span: 6 }}>
          <Button
            onClick={() => {
              window.open('/estoque.xlsx');
            }}
            type="primary"
            size="large"
            block
          >DOWNLOAD PLANILHA EXEMPLO
          </Button>
        </Col>

        <Col md={{ span: 12 }} lg={{ span: 12 }}>
          <Typography.Paragraph>
            Para cadastrar produtos em lote faça o Download da planilha de exemplo e preencha respeitando as colunas.
          </Typography.Paragraph>

          <Typography.Paragraph>
            Depois faça upload da planilha nesta página.
          </Typography.Paragraph>
        </Col>


        <Col md={{ span: 24 }} lg={{ span: 24 }} style={{ background: '#FFFFFF', padding: 0, borderRadius: 4 }}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Clique ou arraste a planilha para esta área para fazer o upload</p>
            <p className="ant-upload-hint">
              {/* Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files */}
            </p>
          </Dragger>
        </Col>
      </Container>
    </>
  );
}
