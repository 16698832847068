import { call, put, select } from 'redux-saga/effects';
import { message } from 'antd';

import api from '~/services/api';

import { Creators as ProductAction } from '~/store/ducks/product';

export function* requestProduct() {
  try {
    const { data } = yield call(api.get, 'products');

    yield put(ProductAction.getSuccessProduct(data));
  } catch (err) {
    //
  }
}

export function* requestBackgroundProduct() {
  try {
    const { data } = yield call(api.get, 'products');

    yield put(ProductAction.getSuccessProduct(data));
  } catch (err) {
    //
  }
}

export function* requestBackgroundProductSemMsg() {
  try {
    const { data } = yield call(api.get, 'products');

    yield put(ProductAction.getSuccessProduct(data));
  } catch (err) {
    //
  }
}


export function* deleteProduct({ payload: { data: id } }) {
  try {
    const { products } = yield select((state) => state.product);
    const newProducts = products.filter((product) => product.id !== id);

    yield call(api.delete, `products/${id}`);
    yield put(ProductAction.successDelete(newProducts));

    // notification.success({
    //   message: 'Produto Deletado!',
    //   description: 'Produto deletado com sucesso',
    //   placement: 'topRight',
    // });
    message.success('Produto deletado com sucesso!');
  } catch (err) {
    message.error('Não foi possível deletar este produto');
  }
}

export function* deleteProductSemMsg({ payload: { data: id } }) {
  try {
    const { products } = yield select((state) => state.product);
    const newProducts = products.filter((product) => product.id !== id);

    yield call(api.delete, `products/${id}`);
    yield put(ProductAction.successDelete(newProducts));

    // notification.success({
    //   message: 'Produto Deletado!',
    //   description: 'Produto deletado com sucesso',
    //   placement: 'topRight',
    // });

    console.log('Produto deletado com sucesso!');
  } catch (err) {
    console.log('Não foi possível deletar este produto');
  }
}
