import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Skeleton, Table, Typography, Button, Modal, Drawer, Tooltip, Input, message,
} from 'antd';

import { IoIosHelpCircle } from 'react-icons/io';
import { LoadingOutlined } from '@ant-design/icons';
import { Container } from './styles';
import { Col, Label } from '~/styles/components';


import { Creators as ClientCreators } from '~/store/ducks/client';

import socket from '~/services/notification';

import api from '~/services/api';

import { HandleFormatMoney } from '~/services/utils';

const { TextArea } = Input;


export default function NovoProdutoLote() {
  const dispatch = useDispatch();
  const io = socket();
  const { user } = useSelector((state) => state.user);
  const { clients, loading } = useSelector((state) => state.client);
  const [dataSource, setDataSouce] = useState([]);
  const [loadingg, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [messageGeneric, setMessageGeneric] = useState({});
  const [messageSend, setMessage] = useState({});
  const [drawerShowGeneric, setDrawerGeneric] = useState(false);
  const [drawerShow, setDrawer] = useState(false);
  const [dataMessage, setDataMessage] = useState('');
  const [plan, setPlan] = useState(false);

  useEffect(() => {
    api.post('/admin/clients/messages/generic/estimate').then(({ data }) => {
      if (data.status !== 403) {
        setPlan(true);
      }
      setMessageGeneric(data);
    });
    api.post('/admin/clients/messages/estimate').then(({ data }) => {
      if (data.status !== 403) {
        setPlan(true);
      }
      setMessage(data);
    });
  }, []);

  useEffect(() => {
    dispatch(ClientCreators.getRequestClient());
    io.on('REMOVE_CART', () => {
      dispatch(ClientCreators.getRequestBackgroundClient());
    });
    io.on('ADD_CART', () => {
      dispatch(ClientCreators.getRequestBackgroundClient());
    });
    io.on('PURSHASE', () => {
      dispatch(ClientCreators.getRequestBackgroundClient());
    });
  }, [dispatch, io]);

  useEffect(() => {
    if (clients.length) {
      const source = clients.map((client, index) => ({
        key: index,
        id: client.id,
        name: client.name || '-',
        phone: client.phone,
        email: client.email || '-',
        cart: client.cart || false,
        in_shopping_string: ((client.cart && client.cart.products.items.length > 0) ? 'Sim' : 'Não'),
        in_shopping: (!!(client.cart && client.cart.products.items.length > 0)),
      }));

      source.sort((a) => (a.in_shopping ? -1 : 1));

      setDataSouce(source);
    }
  }, [clients]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleDrawerGeneric = () => {
    setDrawerGeneric(true);
    api.post('/admin/clients/messages/generic/estimate').then(({ data }) => {
      if (data.status !== 403) {
        setPlan(true);
      }
      setMessageGeneric(data);
    });
    api.post('/admin/clients/messages/estimate').then(({ data }) => {
      if (data.status !== 403) {
        setPlan(true);
      }
      setMessage(data);
    });
  };

  const handleDrawerPadrao = () => {
    setDrawer(true);
    api.post('/admin/clients/messages/generic/estimate').then(({ data }) => {
      if (data.status !== 403) {
        setPlan(true);
      }
      setMessageGeneric(data);
    });
    api.post('/admin/clients/messages/estimate').then(({ data }) => {
      if (data.status !== 403) {
        setPlan(true);
      }
      setMessage(data);
    });
  };

  function onClose() {
    setDrawerGeneric(false);
    setDrawer(false);
  }

  const sendMessage = () => {
    setLoading(true);
    api.post('/admin/clients/messages', { message: dataMessage }).then(({ data }) => {
      if (data.statusCode === 400) {
        setLoading(false);
        setDrawerGeneric(false);
        setDrawer(false);
        message.error('Ocorreu um erro no envio. Tente novamente em alguns instantes.');
      }
      if (data.saldo === false) {
        setLoading(false);
        setDrawerGeneric(false);
        setDrawer(false);
        message.error('Saldo insuficiente!');
      } else {
        setLoading(false);
        setDrawerGeneric(false);
        setDrawer(false);
        message.success('Mensagens enviada com sucesso!');
      }
    }).catch((error) => {
      setLoading(false);
      setDrawerGeneric(false);
      setDrawer(false);
      message.error('Não foi possível enviar as mensagens!');
    });
  };

  const sendMessageGeneric = () => {
    setLoading(true);
    api.post('/admin/clients/messages/generic').then(({ data }) => {
      if (data.statusCode === 400) {
        setLoading(false);
        setDrawerGeneric(false);
        setDrawer(false);
        message.error('Ocorreu um erro no envio. Tente novamente em alguns instantes.');
      }
      if (data.saldo === false) {
        setLoading(false);
        setDrawerGeneric(false);
        setDrawer(false);
        message.error('Saldo insuficiente!');
      } else {
        setLoading(false);
        setDrawerGeneric(false);
        setDrawer(false);
        message.success('Mensagens enviada com sucesso!');
      }
    }).catch((error) => {
      setLoading(false);
      setDrawerGeneric(false);
      setDrawer(false);
      message.error('Não foi possível enviar as mensagens!');
    });
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: 'Em Compras?',
      dataIndex: 'in_shopping_string',
      key: 'in_shopping_string',
      sorter: (a) => (a.in_shopping ? -1 : 1),
      render: (text, client) => (
        <span style={{
          color: client.in_shopping ? 'green' : 'inherit',
          fontWeight: client.in_shopping ? 'bold' : 'normal',
        }}
        >{text}
        </span>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Col md={{ span: 18 }} lg={{ span: 18 }}>
          <Typography.Title level={3}>Clientes</Typography.Title>
        </Col>
        <Col md={{ span: 6 }} lg={{ span: 6 }}>
          <Button
            onClick={() => showModal()}
            type="primary"
            size="large"
            block
          >Enviar mensagem para clientes
          </Button>
          <Modal
            visible={visible}
            title="Qual mensagem deseja enviar?"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={() => handleCancel()}>
                Cancelar
              </Button>,
            ]}
          >
            {plan ? (
              <div>
                <Button
                  onClick={() => { handleDrawerGeneric(); handleCancel(); }}
                  type="primary"
                  size="large"
                  block
                >Enviar mensagem padrão
                </Button>
                <br />
                <br />
                <Button
                  onClick={() => { handleDrawerPadrao(); handleCancel(); }}
                  type="primary"
                  size="large"
                  block
                >Enviar mensagem personalizada
                </Button>
              </div>
            )
              : (
                <div>Você ainda não contratou um plano!</div>
              )}
          </Modal>
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 24 }}>
          <Skeleton active paragraph={{ rows: 8 }} loading={loading}>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{ position: ['topRight', 'bottomRight'] }}
              scroll={{ y: 400, scrollToFirstRowOnChange: true }}
              expandable={{
                expandedRowRender: (client) => (
                  <div style={{
                    margin: 0,
                  }}
                  >
                    {client.cart && client.cart.products.total > 0 ? (
                      <>
                        {client.cart.products.items.map((item) => (
                          <div key={item.id}>
                            <strong>Produto: </strong>{item.name}{' | '}
                            <strong>Preço: </strong>R$ {HandleFormatMoney(item.price)}{' | '}
                            <strong>Quantidade: </strong>{item.quantity}
                          </div>
                        ))}
                        <div style={{ marginTop: 10 }}>
                          <strong>Total: </strong>
                          R$ {HandleFormatMoney(client.cart.products.total)}
                        </div>
                      </>
                    ) : (
                      <div>
                        Cliente não está em compras
                      </div>
                    )}
                  </div>
                ),
                rowExpandable: (record) => record.name !== 'Not Expandable',
              }}

            />
          </Skeleton>
        </Col>

        <Drawer
          width={640}
          placement="right"
          closable
          onClose={onClose}
          title="Enviar mensagem padrão"
          visible={drawerShowGeneric}
        >
          <>
            <h3>Detalhes</h3>

            <div style={{ marginBottom: '20px' }}>
              <div>
                <span style={{ fontSize: '15px' }} /><br />
                <strong>Custo total do disparo: </strong>{messageGeneric.amount_charged}<br />
                <strong>Quantidade: </strong>{messageGeneric.contacts }<br />

                <div style={{
                  margin: '5px 0', width: '100%', height: '2px', background: '#f0f0f0',
                }}
                />
              </div>
            </div>

            <div>
              {messageGeneric.contacts ? (
                messageGeneric.saldo ? (
                  <div>
                    <Label>Mensagem
                      <Tooltip
                        arrowPointAtCenter
                        placement="topLeft"
                        title="Mensagem padrão que será enviada para todos os seus clientes."
                      >
                        <IoIosHelpCircle />
                      </Tooltip>
                    </Label>
                    <p>
                      Olá, tudo bem? Eu, sou assistente virtual {user.name}.
                      Caso você desejar conhecer mais sobre mim é só me chamar.
                    </p>

                    <Button
                      style={{ marginTop: '10px' }}
                      type="primary"
                      onClick={() => { sendMessageGeneric(); }}
                    >
                      {loadingg && <LoadingOutlined />}
                      Enviar
                    </Button>

                  </div>
                ) : (
                  <div>Saldo insuficiente!</div>
                )
              ) : (
                <div>Você não possui clientes!</div>
              )}
            </div>
          </>
        </Drawer>

        <Drawer
          width={640}
          placement="right"
          closable
          onClose={onClose}
          title="Enviar mensagem personalizada"
          visible={drawerShow}
        >
          <>
            <h3>Detalhes</h3>

            <div style={{ marginBottom: '20px' }}>
              <div>
                <span style={{ fontSize: '15px' }} /><br />
                <strong>Custo total do disparo: </strong>{messageSend.amount_charged}<br />
                <strong>Quantidade: </strong>{messageSend.contacts_actives}<br />

                <div style={{
                  margin: '5px 0', width: '100%', height: '2px', background: '#f0f0f0',
                }}
                />
              </div>
            </div>

            <div>
              {messageSend.contacts ? (
                messageSend.saldo ? (
                  <div>
                    <Col lg={{ span: 18 }}>
                      <div>
                        <Label>Mensagem
                          <Tooltip
                            arrowPointAtCenter
                            placement="topLeft"
                            title="Informe a mensagem que será enviada para os contatos das últimas 24 horas."
                          >
                            <IoIosHelpCircle />
                          </Tooltip>
                        </Label>
                        <TextArea
                          placeholder="texto"
                          type="text"
                          name="message"
                          value={dataMessage}
                          onChange={(e) => setDataMessage(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Button
                      style={{ marginTop: '10px' }}
                      type="primary"
                      onClick={() => { sendMessage(); }}
                    >
                      {loadingg && <LoadingOutlined />}
                      Enviar
                    </Button>

                  </div>
                ) : (
                  <div>Saldo insuficiente!</div>
                )
              ) : (
                <div>0 clientes ativos!</div>
              )}
            </div>
          </>
        </Drawer>

      </Container>
    </>
  );
}
